import { supabase } from '../utils/supabase';
import { PRODUCT_TYPE } from '../constants';
import { handleImageUpload } from './fileUploadHandler';
import { checkUserRole, getUserDetails } from './authHandler';

const productHandler = {
  create: async params => {
    // Get current user details
    const { id: currentUserId, role: userRole } = await getUserDetails();

    // Check if user has permission to create products
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: "Access denied: You don't have permission to create products",
      });
    }

    // Handle image upload if image data is provided
    if (params?.data?.image) {
      try {
        const publicURL = await handleImageUpload(params?.data?.image);
        params.data.image_url = publicURL;
        delete params.data.image;
      } catch (error) {
        return Promise.reject(error);
      }
    }

    // Create the product in the 'products' table
    const { data, error } = await supabase
      ?.from('products')
      ?.insert([
        {
          name: params?.data?.name,
          description: params?.data?.description,
          price: params?.data?.price,
          product_type: params?.data?.product_type,
          image_url: params?.data?.image_url,
          image_alt: params?.data?.image_alt,
          created_by: currentUserId,
        },
      ])
      ?.select();

    if (error) throw error;

    // Handle product configurations for 'VEHICLE' type
    if (
      params?.data?.product_type === PRODUCT_TYPE?.VEHICLE &&
      params?.data?.configurations
    ) {
      for (const category in params?.data?.configurations) {
        const values = params?.data?.configurations?.[category];
        const uppercasedCategory = category?.toUpperCase();

        // Fetch existing configuration options
        const { data: existingOptions, error: fetchError } = await supabase
          ?.from('configuration_options')
          ?.select('id, value')
          ?.eq('category', uppercasedCategory)
          ?.in(
            'value',
            values?.map(v => v?.value)
          );

        if (fetchError) throw fetchError;

        // Identify new values that need to be created
        const existingValues = new Set(existingOptions?.map(o => o?.value));
        const newValues = values?.filter(v => !existingValues?.has(v?.value));

        // Insert new configuration options
        if (newValues?.length > 0) {
          const { data: newOptions, error: insertError } = await supabase
            ?.from('configuration_options')
            ?.insert(
              newValues?.map(v => ({
                category: uppercasedCategory,
                value: v?.value,
              }))
            )
            ?.select('id, value');

          if (insertError) throw insertError;

          existingOptions?.push(...newOptions);
        }

        // Prepare product configurations to be inserted
        const productConfigurations = existingOptions?.map(option => ({
          product_id: data?.[0]?.id,
          configuration_option_id: option?.id,
        }));

        // Insert product configurations
        const { error: configError } = await supabase
          ?.from('product_configurations')
          ?.insert(productConfigurations);

        if (configError) throw configError;
      }
    }

    return { data: { id: data?.[0]?.id, ...params?.data } };
  },

  update: async params => {
    // Check if user has permission to update products
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: "Access denied: You don't have permission to update products",
      });
    }

    // Handle image upload if image data is provided
    if (params?.data?.image) {
      try {
        const publicURL = await handleImageUpload(params?.data?.image);
        params.data.image_url = publicURL;
        delete params.data.image;
      } catch (error) {
        return Promise.reject(error);
      }
    }

    // Update the product in the 'products' table
    const { error } = await supabase
      ?.from('products')
      ?.update({
        name: params?.data?.name,
        description: params?.data?.description,
        price: params?.data?.price,
        image_url: params?.data?.image_url,
        image_alt: params?.data?.image_alt,
      })
      ?.eq('id', params?.id);

    if (error) throw error;

    // Handle 'VEHICLE' product configurations
    if (
      params?.data?.product_type === PRODUCT_TYPE?.VEHICLE &&
      params?.data?.configurations
    ) {
      const newConfigurations = params?.data?.configurations?.filter(
        config => config?.isNew && config?.value
      );
      const existingConfigurations = params?.data?.configurations?.filter(
        config => config?.id && !config?.isNew && config?.value
      );

      // Fetch and update existing configuration options
      const { data: existingOptions, error: fetchError } = await supabase
        ?.from('configuration_options')
        ?.select('id, category, value')
        ?.in(
          'id',
          existingConfigurations?.map(config => config?.id)
        );

      if (fetchError) throw fetchError;

      if (existingConfigurations?.length > 0) {
        const configurationsToUpdate = existingConfigurations?.map(config => ({
          id: config?.id,
          category: existingOptions?.find(option => option?.id === config?.id)
            ?.category,
          value: config?.value,
        }));

        const { error: updateError } = await supabase
          ?.from('configuration_options')
          ?.upsert(configurationsToUpdate, { onConflict: 'id' });

        if (updateError) throw updateError;
      }

      // Insert new configuration options
      let insertedConfigs = [];
      if (newConfigurations?.length > 0) {
        const { data: newConfigData, error: insertConfigError } = await supabase
          ?.from('configuration_options')
          ?.insert(
            newConfigurations?.map(config => ({
              category: config?.category,
              value: config?.value,
            }))
          )
          ?.select();

        if (insertConfigError) throw insertConfigError;

        insertedConfigs = newConfigData;
      }

      // Link configurations to the product
      const allConfigurationsToLink = [
        ...existingConfigurations?.map(config => ({
          product_id: params?.id,
          configuration_option_id: config?.id,
        })),
        ...insertedConfigs?.map(config => ({
          product_id: params?.id,
          configuration_option_id: config?.id,
        })),
      ];

      if (allConfigurationsToLink?.length > 0) {
        const { error: linkError } = await supabase
          ?.from('product_configurations')
          ?.upsert(allConfigurationsToLink, {
            onConflict: ['product_id', 'configuration_option_id'],
          });

        if (linkError) throw linkError;
      }
    }

    // Fetch updated product details
    const { data: updatedProduct, error: fetchError } = await supabase
      ?.from('products')
      ?.select('*')
      ?.eq('id', params?.id)
      ?.single();

    if (fetchError) throw fetchError;

    return { data: updatedProduct };
  },

  delete: async params => {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: "Access denied: You don't have permission to delete products",
      });
    }

    // Delete product configurations and product record
    await supabase
      ?.from('product_configurations')
      ?.delete()
      ?.eq('product_id', params?.id);
    const { error } = await supabase
      ?.from('products')
      ?.delete()
      ?.eq('id', params?.id);

    if (error) throw error;
    return { data: params?.previousData };
  },

  deleteMany: async params => {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: "Access denied: You don't have permission to delete products",
      });
    }

    // Fetch product image URLs
    const { data: products } = await supabase
      ?.from('products')
      ?.select('image_url')
      ?.in('id', params?.ids);

    // Delete associated images if available
    if (products?.length > 0) {
      const imagePaths = products
        ?.filter(product => product?.image_url)
        ?.map(product => product?.image_url?.split('/')?.pop());

      if (imagePaths?.length > 0) {
        await supabase?.storage?.from('product-images')?.remove(imagePaths);
      }
    }

    // Delete configurations and product records
    await supabase
      ?.from('product_configurations')
      ?.delete()
      ?.in('product_id', params?.ids);
    const { error } = await supabase
      ?.from('products')
      ?.delete()
      ?.in('id', params?.ids);

    if (error) throw error;
    return { data: params?.ids };
  },

  toggleStatus: async params => {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: "Access denied: You don't have permission to modify products",
      });
    }

    const { error } = await supabase
      ?.from('products')
      ?.update({
        status: params.status,
        updated_at: new Date().toISOString(),
      })
      ?.eq('id', params.id);

    if (error) throw error;

    // Fetch updated product details
    const { data: updatedProduct, error: fetchError } = await supabase
      ?.from('products')
      ?.select('*')
      ?.eq('id', params.id)
      ?.single();

    if (fetchError) throw fetchError;

    return { data: updatedProduct };
  },
};

export default productHandler;
