import { supabase } from '../utils/supabase';

export const getUserDetails = async () => {
  const {
    data: { user },
    error: userError,
  } = await supabase.auth.getUser();
  if (userError || !user) {
    // eslint-disable-next-line no-console
    console.error('Error fetching authenticated user:', userError);
    return null;
  }

  const { data: userDetails, error: userDetailsError } = await supabase
    .from('user_details')
    .select('role')
    .eq('id', user.id)
    .single();

  if (userDetailsError && userDetailsError.code !== 'PGRST116') {
    // eslint-disable-next-line no-console
    console.error('Error fetching current user details:', userDetailsError);
    return null;
  }

  return { ...user, ...userDetails };
};

export const checkUserRole = async () => {
  const {
    data: { user },
    error: userError,
  } = await supabase.auth.getUser();
  if (userError || !user) {
    // eslint-disable-next-line no-console
    console.error('Error fetching authenticated user:', userError);
    return null;
  }

  const { data: userDetails, error: userDetailsError } = await supabase
    .from('user_details')
    .select('role')
    .eq('id', user.id)
    .single();

  if (userDetailsError && userDetailsError.code !== 'PGRST116') {
    // eslint-disable-next-line no-console
    console.error('Error fetching current user details:', userDetailsError);
    return null;
  }

  return userDetails?.role;
};
