export const sendNotification = async (
  supabase,
  userId,
  enquiryId,
  enquiryStatus,
  notificationTitle,
  notificationBody
) => {
  try {
    const { error } = await supabase?.functions?.invoke('send-notification', {
      body: JSON.stringify({
        user_id: userId,
        data: {
          enquiry_id: enquiryId,
          enquiry_status: enquiryStatus,
        },
        notification: {
          title: notificationTitle,
          body: notificationBody,
        },
      }),
    });

    if (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to call Edge Function:', error);
      throw new Error(`Edge Function call failed`);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error calling Edge Function:', error);
  }
};
