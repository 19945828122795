import React from 'react';
import Dashboard from './Dashboard.js';

import { usePermissions } from 'react-admin';

const DashboardContainer = () => {
  const { permissions } = usePermissions();

  const rolePermissions = {
    ADMIN: {
      canViewUsers: true,
      canViewEnquiries: true,
      canViewProducts: true,
    },
    SALES_MANAGER: {
      canViewUsers: false,
      canViewEnquiries: true,
      canViewProducts: true,
    },
  };

  const roleConfig = rolePermissions[permissions] || {};

  return <Dashboard {...roleConfig} />;
};

export default DashboardContainer;
