import { useNotify } from 'react-admin';
import { useState } from 'react';
import {
  Button,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';

const CreateEnquiryToolbar = ({ onSubmit, selectedProducts }) => {
  const notify = useNotify();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const handleSubmit = () => {
    if (selectedProducts.length === 0) {
      notify('Please select at least one product', { type: 'warning' });
      return;
    }

    handleOpenConfirmation();
  };

  const handleConfirmSubmit = () => {
    handleCloseConfirmation();
    onSubmit();
    notify('Enquiry successfully created', { type: 'success' });
  };

  const isDisabled = selectedProducts.length === 0;

  return (
    <>
      <Toolbar style={{ backgroundColor: 'inherit' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{
            padding: 16,
            margin: 16,
            backgroundColor: isDisabled ? '#758290' : undefined,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
          }}
          disabled={isDisabled}
        >
          Create Enquiry
        </Button>
      </Toolbar>

      <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
        <DialogTitle>Confirm Enquiry Creation</DialogTitle>

        <DialogContent>
          <Typography>Are you sure you want to create the enquiry?</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseConfirmation}>Cancel</Button>

          <Button onClick={handleConfirmSubmit} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateEnquiryToolbar;
