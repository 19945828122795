import { CommonRegex } from '../../regexes';
import { getFormFieldText } from '../../helpers';

export const LoginFormikValidator = (values, formFields) => {
  const errors = {};

  if (!values?.email) {
    errors.email = getFormFieldText(formFields, 'email', 'required_error');
  } else if (values?.email && !CommonRegex?.email?.test(values?.email)) {
    errors.email = getFormFieldText(formFields, 'email', 'validation_error');
  }

  if (!values?.password) {
    errors.password = getFormFieldText(
      formFields,
      'password',
      'required_error'
    );
  } else if (
    values?.password &&
    !CommonRegex?.password?.test(values?.password)
  ) {
    errors.password = getFormFieldText(
      formFields,
      'password',
      'validation_error'
    );
  }

  return errors;
};
