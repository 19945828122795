import React, { memo, useCallback } from 'react';
import { useRedirect } from 'react-admin';

import styles from './styles.module.css';

const ProductField = memo(({ label, value }) => (
  <div className={styles?.product_field}>
    <span className={styles?.field_label}>{label}:</span>

    <span className={styles?.field_value}>{value}</span>
  </div>
));

const formatPrice = price => {
  return price
    ? new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
      }).format(price)
    : 'N/A';
};

const ProductCard = memo(({ record }) => {
  const redirect = useRedirect();

  const handleClick = useCallback(() => {
    redirect('show', 'products', record?.id);
  }, [redirect, record?.id]);

  return (
    <div className={styles?.product_card} onClick={handleClick}>
      <div className={styles?.product_image}>
        <img src={record?.image_url} alt={record?.image_alt} />
      </div>

      <div className={styles?.product_details}>
        <ProductField label="Product Number" value={record?.product_number} />

        <ProductField label="Name" value={record?.name} />

        <ProductField label="Price" value={formatPrice(record?.price)} />
      </div>
    </div>
  );
});

export default ProductCard;
