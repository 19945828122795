import React from 'react';
import { AppBar, Layout } from 'react-admin';
import { styled } from '@mui/material/styles';

const StyledAppBar = styled(AppBar)({
  backgroundColor: 'black',
});

const CustomAppBar = props => <StyledAppBar {...props} />;

const CustomLayout = props => <Layout {...props} appBar={CustomAppBar} />;

export default CustomLayout;
