import React, { memo, useCallback } from 'react';
import { Button, useListContext } from 'react-admin';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import styles from './styles.module.css';

const SortableButton = memo(({ field, label }) => {
  const { setSort, sort } = useListContext();

  const handleSort = useCallback(() => {
    const newOrder =
      sort?.field === field && sort?.order === 'ASC' ? 'DESC' : 'ASC';
    setSort?.({ field, order: newOrder });
  }, [sort?.field, sort?.order, field, setSort]);

  const isActive = sort?.field === field;
  const order = isActive ? sort?.order : null;

  return (
    <Button onClick={handleSort} className={styles?.sortable_button}>
      <span>{label}</span>

      {isActive && (
        <span
          className={`${styles?.sort_indicator} ${
            styles?.[order?.toLowerCase()]
          }`}
        >
          {order === 'ASC' ? (
            <ArrowUpwardIcon fontSize="small" />
          ) : (
            <ArrowDownwardIcon fontSize="small" />
          )}
        </span>
      )}
    </Button>
  );
});

export default SortableButton;
