export const USER_ROLES = {
  ADMIN: 'ADMIN',
  SALES_MANAGER: 'SALES_MANAGER',
};

export const RESOURCES = {
  USERS: 'users',
  PRODUCTS: 'products',
  ENQUIRIES: 'enquiries',
  QUOTATIONS: 'quotations',
  INVOICES: 'invoices',
  PAYMENTS: 'payments',
};

export const VIEWS = {
  DASHBOARD: 'dashboard',
  LIST: 'list',
  SHOW: 'show',
  EDIT: 'edit',
  CREATE: 'create',
};

export const ENQUIRY_STATUS = {
  SUBMITTED: 'ENQUIRY_SUBMITTED',
  QUOTATION_GENERATED: 'QUOTATION_GENERATED',
  CLOSED: 'ENQUIRY_CLOSED',
};

export const QUOTATION_STATUS = {
  PENDING_REVIEW: 'CUSTOMER_PENDING_REVIEW',
  ACCEPTED: 'CUSTOMER_ACCEPTED',
  REJECTED: 'CUSTOMER_REJECTED',
  PARTIALLY_ACCEPTED: 'CUSTOMER_PARTIALLY_ACCEPTED',
};

export const INVOICE_STATUS = {
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
  PENDING: 'INVOICE_PENDING',
};

export const PAYMENT_STATUS = {
  VERIFIED: true,
  NOT_VERIFIED: false,
};

export const PRODUCT_TYPE = {
  VEHICLE: 'VEHICLE',
  VEHICLE_PART: 'VEHICLE_PART',
};

export const DATE_FORMAT_OPTIONS = {
  timeZone: 'Asia/Kolkata',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

export const PRODUCT_STATUS_CHOICES = [
  { id: 'ALL', name: 'All' },
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
];

export const CURRENCY_OPTIONS = { style: 'currency', currency: 'INR' };

export const PRODUCT_SORT_FIELDS = [
  { field: 'product_number', label: 'Product Number' },
  { field: 'name', label: 'Name' },
  { field: 'price', label: 'Price' },
];

export const PRODUCT_OPTION = [
  { id: 'ALL', name: 'All ' },
  { id: 'VEHICLE', name: 'Vehicles' },
  { id: 'VEHICLE_PART', name: 'Vehicle Parts' },
];
