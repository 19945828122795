import { supabase } from '../utils/supabase';

export const handleImageUpload = async image => {
  const {
    data: { user },
  } = await supabase.auth.getUser();
  if (!user) throw new Error('No authenticated user');

  const file = image.rawFile;
  const fileExt = file.name.split('.').pop();
  const fileName = `${Math.random()}.${fileExt}`;
  const filePath = `${user.id}/${fileName}`;

  let { error: uploadError } = await supabase.storage
    .from('product-images')
    .upload(filePath, file, { upsert: true });

  if (uploadError) {
    throw new Error(`Error uploading file: ${uploadError.message}`);
  }

  const {
    data: { publicUrl },
    error,
  } = supabase.storage.from('product-images').getPublicUrl(filePath);

  if (error) {
    throw new Error(`Error getting public URL: ${error.message}`);
  }

  return publicUrl;
};
