import { useInput } from 'react-admin';

import styles from './styles.module.css';

export const ProductInput = ({
  source,
  label,
  multiline = false,
  rows = 4,
  type = 'text',
  disabled = false,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    source,
    validate: rest.validate,
    ...rest,
  });

  return (
    <div className={styles.inputContainer}>
      <label className={styles.label} htmlFor={source}>
        {label}
        {isRequired && ' *'}
      </label>
      {multiline ? (
        <textarea
          id={source}
          className={`${styles.input} ${styles.textarea}`}
          disabled={disabled}
          rows={rows}
          {...field}
          {...rest}
        />
      ) : (
        <input
          id={source}
          className={styles.input}
          type={type}
          disabled={disabled}
          {...field}
          {...rest}
        />
      )}
      {isSubmitted && error && (
        <span className={styles.error}>{error.message}</span>
      )}
    </div>
  );
};
