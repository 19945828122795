import { getFormFieldText } from '../../helpers';
import { CommonRegex } from '../../regexes';

export const InvoiceFormValidator = (values, isUpload, formFields) => {
  const errors = {};

  if (!values.billTo?.trim()) {
    errors.billTo = getFormFieldText(formFields, 'billTo', 'required_error');
  }

  if (!values.shipTo?.trim()) {
    errors.shipTo = getFormFieldText(formFields, 'shipTo', 'required_error');
  }

  if (isUpload) {
    if (!values.invoiceNumber?.trim()) {
      errors.invoiceNumber = getFormFieldText(
        formFields,
        'invoiceNumber',
        'required_error'
      );
    }

    if (values.invoiceAmount === undefined || values.invoiceAmount === '') {
      errors.invoiceAmount = getFormFieldText(
        formFields,
        'invoiceAmount',
        'required_error'
      );
    } else if (!CommonRegex.amount?.test(String(values.invoiceAmount))) {
      errors.invoiceAmount = getFormFieldText(
        formFields,
        'invoiceAmount',
        'validation_error'
      );
    }
  }

  return errors;
};
