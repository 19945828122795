import React from 'react';

import { useRecordContext } from 'react-admin';
import { formatStatusName } from '../../../common/helpers';

export const FormatStatusField = () => {
  const record = useRecordContext();

  if (!record) return null;

  return <span>{formatStatusName(record?.status)}</span>;
};
