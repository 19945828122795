import { getOne } from '../resourceHandlers/getOneHandler';
import { getList } from '../resourceHandlers/getListHandler';
import { supabase } from '../utils/supabase';
import { handleEnquiry } from 'supabase-connect';
import { supabaseDataProvider } from 'ra-supabase';
import { ENQUIRY_STATUS, QUOTATION_STATUS } from '../constants';
import { sendNotification } from '../resourceHandlers/sendNotification';
import { checkUserRole, getUserDetails } from '../resourceHandlers/authHandler';

import userHandler from '../resourceHandlers/userHandler';
import productHandler from '../resourceHandlers/productHandler';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

const baseDataProvider = supabaseDataProvider({
  instanceUrl: supabaseUrl,
  apiKey: supabaseAnonKey,
  supabase,
});

const dataProvider = {
  ...baseDataProvider,

  getList: async (resource, params) => {
    return await getList(resource, params, baseDataProvider);
  },

  getOne: async (resource, params) => {
    return await getOne(resource, params, baseDataProvider);
  },

  create: async (resource, params) => {
    if (resource === 'users') {
      return userHandler.create(params);
    } else if (resource === 'products') {
      return productHandler.create(params);
    } else if (resource === 'enquiries') {
      return dataProvider.createEnquiry(params);
    }

    if (resource === 'quotations') {
      const { id: currentUserId, role } = await getUserDetails();

      if (!['ADMIN', 'SALES_MANAGER'].includes(role)) {
        return Promise.reject({
          message:
            "Access denied: You don't have permission to create quotations",
        });
      }

      const { data, error } = await supabase
        .from('quotations')
        .insert([{ ...params.data, created_by: currentUserId }])
        .select();

      if (error) {
        // eslint-disable-next-line no-console
        console.error('Error creating quotation:', error);
        throw error;
      }

      return { data: data[0] };
    } else {
      const userRole = await checkUserRole();
      if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
        return Promise.reject({
          message: `Access denied: You don't have permission to create ${resource}`,
        });
      }
    }
    return baseDataProvider.create(resource, params);
  },

  update: async (resource, params) => {
    if (resource === 'users') {
      return userHandler.update(params);
    } else if (resource === 'products') {
      return productHandler.update(params);
    } else if (resource === 'enquiries') {
      return dataProvider.updateEnquiry(params);
    } else if (resource === 'quotations') {
      const userRole = await checkUserRole();
      if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
        return Promise.reject({
          message: `Access denied: You don't have permission to update this ${resource.slice(
            0,
            -1
          )}`,
        });
      }

      const { data, error } = await supabase
        .from(resource)
        .update(params.data)
        .eq('id', params.id)
        .select();

      if (error) {
        // eslint-disable-next-line no-console
        console.error(`Error updating ${resource.slice(0, -1)}:`, error);
        throw error;
      }

      return { data: data[0] };
    } else {
      const userRole = await checkUserRole();
      if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
        return Promise.reject({
          message: `Access denied: You don't have permission to update this ${resource.slice(
            0,
            -1
          )}`,
        });
      }
    }
    return baseDataProvider.update(resource, params);
  },

  createEnquiry: async params => {
    const currentUserId = params?.data?.user_id;

    if (!currentUserId) {
      throw new Error('Unable to process request: User ID must be specified.');
    }

    try {
      const enquiry = await handleEnquiry(params.data, params?.data?.user_id);

      return {
        data: {
          id: enquiry?.id,
          ...enquiry,
        },
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating enquiry:', error);
      throw error;
    }
  },

  updateEnquiry: async params => {
    const { id: currentUserId, role } = await getUserDetails();

    const data = await handleEnquiry(
      { ...params.data, id: params.id },
      currentUserId,
      role
    );
    return { data };
  },

  updateEnquiryTracking: async (
    enquiryId,
    trackingUrl,
    trackingInformation
  ) => {
    const { data, error } = await supabase?.rpc('update_enquiry_tracking', {
      _enquiry_id: enquiryId,
      _tracking_url: trackingUrl,
      _tracking_information: trackingInformation,
    });
    if (error) throw error;
    return { data: data[0] };
  },

  generateQuotation: async ({ enquiryId, products, currencyId }) => {
    try {
      const {
        data: { user: currentUser },
      } = await supabase?.auth.getUser();

      // Calculate total amount
      const totalAmount = products.reduce(
        (sum, p) => sum + p.price * p.quantity,
        0
      );

      // Start a Supabase transaction
      const { data, error } = await supabase
        .from('quotations')
        .insert({
          enquiry_id: enquiryId,
          created_by: currentUser.id,
          quotation_amount: totalAmount,
          currency_id: currencyId,
          status: QUOTATION_STATUS?.PENDING_REVIEW,
        })
        .select()
        .single();

      if (error) {
        throw error;
      }

      const quotationId = data.id;

      // Insert quotation items and collect their IDs
      const productInsertions = products.map(p => ({
        quotation_id: quotationId,
        product_id: p?.isCustom ? null : p?.product_id,
        quantity: p?.quantity,
        price: p?.price,
        is_custom: p?.isCustom || false,
        product_description: p?.isCustom ? p?.product_description : null,
      }));

      const { data: insertedProducts, error: itemsError } = await supabase
        .from('quotation_products')
        .insert(productInsertions)
        .select();

      if (itemsError) {
        throw itemsError;
      }

      const configOptionsInsert = [];

      // Insert configuration options
      insertedProducts.forEach((insertedProduct, index) => {
        const product = products[index];

        if (
          product.configuration_options &&
          product.configuration_options.length > 0
        ) {
          product.configuration_options.forEach(optionId => {
            configOptionsInsert?.push({
              quotation_product_id: insertedProduct?.id,
              configuration_option_id: optionId,
            });
          });
        }
      });

      if (configOptionsInsert.length > 0) {
        const { error: configError } = await supabase
          .from('quotation_configurations')
          .insert(configOptionsInsert);

        if (configError) {
          throw configError;
        }
      }

      // Update enquiry status
      const { error: enquiryError } = await supabase
        .from('enquiries')
        .update({
          status: ENQUIRY_STATUS?.QUOTATION_GENERATED,
          quotation_id: quotationId,
        })
        .eq('id', enquiryId);

      if (enquiryError) {
        throw enquiryError;
      }

      // Fetch the complete quotation data
      const { data: quotationData, error: fetchError } = await supabase
        .from('quotations')
        .select(
          `
          *,
          items:quotation_products(*),
          quotation_number
        `
        )
        .eq('id', quotationId)
        .single();

      if (fetchError) {
        throw fetchError;
      }

      const {
        data: { user_id, enquiry_number, status: enquiryStatus },
      } = await supabase
        .from('enquiries')
        .select('user_id, enquiry_number, status')
        .eq('id', enquiryId)
        .single();

      const notificationTitle = 'Quotation Generated';
      const notificationBody = `Quotation ${quotationData?.quotation_number} has been generated for your enquiry ${enquiry_number}.`;

      await sendNotification(
        supabase,
        user_id,
        enquiryId,
        enquiryStatus,
        notificationTitle,
        notificationBody
      );

      return { data: quotationData };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in generateQuotation:', error);
      throw error;
    }
  },

  generateInvoice: async (quotationId, billTo, shipTo, currencyCode) => {
    try {
      const { data: invoiceData, error: invoiceError } = await supabase?.rpc(
        'generate_invoice',
        {
          p_quotation_id: quotationId,
          p_bill_to: billTo,
          p_ship_to: shipTo,
        }
      );

      if (invoiceError) {
        throw new Error(`Error generating invoice: ${invoiceError.message}`);
      }

      const formData = new FormData();
      formData.append('action', 'generate_pdf');
      formData.append('bill_to', billTo);
      formData.append('ship_to', shipTo);
      formData.append('invoice_data', JSON.stringify(invoiceData));
      formData.append('currency_code', currencyCode);

      const { data: pdfData, error: pdfError } =
        await supabase?.functions?.invoke('generate-pdf', {
          body: formData,
        });

      if (pdfError) {
        throw new Error(`Error generating PDF: ${pdfError?.message}`);
      }

      const { pdf_url } = pdfData;

      // Update the invoice with the generated PDF URL
      const { error: updateError } = await supabase
        .from('invoices')
        .update({ pdf_url })
        .eq('id', invoiceData?.id);

      if (updateError) {
        throw new Error(
          `Error updating invoice with PDF URL: ${updateError.message}`
        );
      }

      const { data } = await supabase
        .from('enquiries')
        .select('user_id, id, enquiry_number, status')
        .eq('quotation_id', quotationId)
        .single();

      const {
        user_id,
        id: enquiryId,
        enquiry_number,
        status: enquiryStatus,
      } = data;

      const notificationTitle = 'Invoice Generated';
      const notificationBody = `Invoice ${invoiceData?.invoice_number} has been generated for your enquiry ${enquiry_number}.`;

      await sendNotification(
        supabase,
        user_id,
        enquiryId,
        enquiryStatus,
        notificationTitle,
        notificationBody
      );

      return {
        data: {
          ...invoiceData,
          pdf_url,
        },
      };
    } catch (error) {
      throw new Error(`Failed to generate invoice: ${error.message}`);
    }
  },

  uploadInvoice: async (
    quotationId,
    invoiceNumber,
    invoiceAmount,
    billTo,
    shipTo,
    file
  ) => {
    try {
      // First, update the quotation status
      const { data: invoiceData, error: uploadError } = await supabase?.rpc(
        'generate_invoice',
        {
          p_quotation_id: quotationId,
          p_bill_to: billTo,
          p_ship_to: shipTo,
        }
      );

      if (uploadError) {
        throw new Error(`Error generating invoice: ${uploadError.message}`);
      }

      const formData = new FormData();
      formData.append('action', 'upload');
      formData.append('file', file);
      formData.append('invoice_data', JSON.stringify(invoiceData));
      formData.append('invoice_number', invoiceNumber);
      formData.append('invoice_amount', invoiceAmount);
      formData.append('bill_to', billTo);
      formData.append('ship_to', shipTo);

      const { data: pdfData, error: pdfError } =
        await supabase?.functions?.invoke('generate-pdf', {
          body: formData,
        });

      if (pdfError) {
        throw new Error(`Error uploading PDF: ${pdfError?.message}`);
      }
      if (pdfError) {
        throw new Error(`Error uploading PDF: ${pdfError?.message}`);
      }

      const { pdf_url } = pdfData;

      // Create the invoice record
      const { error: invoiceError } = await supabase
        .from('invoices')
        .update({
          invoice_number: invoiceNumber,
          invoice_amount: parseFloat(invoiceAmount),
          pdf_url,
        })
        .eq('id', invoiceData?.id);

      if (invoiceError) {
        throw new Error(
          `Error creating invoice record: ${invoiceError?.message}`
        );
      }

      // Update the enquiry status
      const { data } = await supabase
        .from('enquiries')
        .select('user_id, id, enquiry_number, status')
        .eq('quotation_id', quotationId)
        .single();

      const {
        user_id,
        id: enquiryId,
        enquiry_number,
        status: enquiryStatus,
      } = data;

      const notificationTitle = 'Invoice Generated';
      const notificationBody = `Invoice ${invoiceData?.invoice_number} has been generated for your enquiry ${enquiry_number}.`;

      await sendNotification(
        supabase,
        user_id,
        enquiryId,
        enquiryStatus,
        notificationTitle,
        notificationBody
      );

      return {
        data: {
          ...invoiceData,
          pdf_url,
        },
      };
    } catch (error) {
      throw new Error(`Failed to upload invoice: ${error.message}`);
    }
  },

  confirmAdvancedPayment: async paymentId => {
    const { data, error } = await supabase?.rpc('confirm_advanced_payment', {
      p_payment_id: paymentId,
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  },

  toggleStatus: async (resource, params) => {
    if (resource === 'products') {
      return productHandler?.toggleStatus(params);
    }
    return Promise.reject(`toggleStatus not implemented for ${resource}`);
  },

  insertOrUpdateShippingDetails: async params => {
    const { user_id, enquiry_number, enquiry_status } = await params?.data;

    const { data, error } = await supabase.rpc(
      'insert_or_update_shipping_details',
      {
        p_enquiry_id: params.data.enquiry_id,
        p_tracking_url: params.data.tracking_url,
        p_tracking_information: params.data.tracking_information,
      }
    );

    if (error) {
      throw new Error(error.message);
    }

    const notificationTitle = 'Tracking Details Updated';
    const notificationBody = `Shipment tracking details for enquiry ${enquiry_number} have been successfully updated`;

    await sendNotification(
      supabase,
      user_id,
      params.data.enquiry_id,
      enquiry_status,
      notificationTitle,
      notificationBody
    );

    return { data: { id: data, ...params.data } };
  },

  insertShippingImage: async params => {
    const file = params.data.image;
    const enquiryId = params.data.enquiry_id;

    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${enquiryId}/${fileName}`;

    try {
      const { error: uploadError } = await supabase.storage
        .from('shipping_images')
        .upload(filePath, file, { upsert: true });

      if (uploadError) throw uploadError;

      const {
        data: { publicUrl },
        error: urlError,
      } = supabase.storage.from('shipping_images').getPublicUrl(filePath);

      if (urlError) throw urlError;

      const { data, error } = await supabase.rpc('insert_shipping_image', {
        p_enquiry_id: enquiryId,
        p_file_url: publicUrl,
      });

      if (error) throw error;

      return {
        data: {
          id: data,
          enquiry_id: enquiryId,
          image_url: publicUrl,
        },
      };
    } catch (error) {
      throw new Error(error.message);
    }
  },

  deleteShippingImage: async (imageId, enquiryId) => {
    const { data: imageUrl, error: dbError } = await supabase.rpc(
      'delete_shipping_image',
      { p_image_id: imageId }
    );

    if (dbError) throw dbError;

    const fileName = imageUrl.split('/').pop();
    const { error: storageError } = await supabase.storage
      .from('shipping_images')
      .remove([`${enquiryId}/${fileName}`]);

    if (storageError) throw storageError;
  },
};

export default dataProvider;
