import React, { memo, useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  ShowButton,
  Loading,
  Empty,
  FunctionField,
} from 'react-admin';
import { formatCurrency, formatStatusName } from '../../../common/helpers';
import { StatusToggleButton } from '../../../components/StatusToggleButton';

import ProductFilter from './ProductFilter';
import ListActions from './ListActions';
import MobileList from './MobileList';
import FormattedField from './FormattedField.js';

import styles from './styles.module.css';

export const ProductList = memo(props => {
  const [defaultCurrency, setDefaultCurrency] = useState(null);

  useEffect(() => {
    const storedCurrency = localStorage.getItem('defaultCurrency');

    if (storedCurrency) {
      const parsedCurrency = JSON.parse(storedCurrency);
      if (parsedCurrency) {
        setDefaultCurrency(parsedCurrency);
      }
    }
  }, []);

  return (
    <List
      {...props}
      filters={<ProductFilter />}
      actions={<ListActions />}
      bulkActionButtons={false}
      empty={<Empty />}
      loading={<Loading />}
      className={styles?.product_list}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <div className={styles?.desktop_view}>
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="product_number" />

          <TextField source="name" />

          <FormattedField source="product_type" formatter={formatStatusName} />

          <FunctionField
            label="Price"
            render={record => {
              if (record?.price) {
                const formattedAmount = formatCurrency(
                  record.price,
                  defaultCurrency?.code
                );
                return formattedAmount;
              } else {
                return 'N/A';
              }
            }}
          />

          <ImageField
            source="image_url"
            title="image_alt"
            className={styles?.product_image}
          />

          <FormattedField
            source="status"
            formatter={formatStatusName}
            render={record => <StatusToggleButton record={record} />}
          />

          <ShowButton />
        </Datagrid>
      </div>
      <div className={styles?.mobile_view}>
        <MobileList />
      </div>
    </List>
  );
});
