import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  usePermissions,
  SimpleList,
  TextInput,
  FilterButton,
  CreateButton,
  ExportButton,
  TopToolbar,
  SortButton,
  useRecordContext,
  RecordContextProvider,
  useListContext,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';

const UserFilters = [
  <TextInput
    source="q"
    label="Search"
    alwaysOn
    variant="outlined"
    fullWidth
    style={{ marginLeft: '0.5rem' }}
    key="search-input"
  />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton filters={UserFilters} />
    <CreateButton />
    <ExportButton />
    <SortButton fields={['email', 'role', 'id']} />
  </TopToolbar>
);

const CustomItemComponent = ({ isSmall }) => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <div
      style={{
        border: isSmall ? '3px solid #ff5722' : 'none',
        backgroundColor: isSmall ? '#fff3e0' : 'transparent',
        padding: '12px',
        marginBottom: '8px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '4px',
        boxShadow: isSmall ? '0px 2px 5px rgba(0,0,0,0.2)' : 'none',
      }}
    >
      <span style={{ fontWeight: 'bold', marginBottom: '4px' }}>
        {record.email}
      </span>
      <span style={{ opacity: 0.7, fontSize: '0.9em', marginBottom: '2px' }}>
        ID: {record.id}
      </span>
      <span style={{ opacity: 0.7, fontSize: '0.8em' }}>{record.role}</span>
    </div>
  );
};

const CustomIterator = ({ isSmall }) => {
  const { data, isLoading } = useListContext();
  if (isLoading || !data) return null;

  return (
    <div style={{ padding: '0 16px' }}>
      {data.map(record => (
        <RecordContextProvider key={record.id} value={record}>
          <CustomItemComponent isSmall={isSmall} />
        </RecordContextProvider>
      ))}
    </div>
  );
};

export const UserList = () => {
  const { permissions } = usePermissions();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  if (permissions !== 'ADMIN') {
    return null;
  }

  return (
    <List
      filters={UserFilters}
      actions={<ListActions />}
      perPage={10}
      sort={{ field: 'email', order: 'ASC' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record.email}
          secondaryText={record => `ID: ${record.id}`}
          tertiaryText={record => record.role}
          linkType="show"
        >
          <CustomIterator isSmall={isSmall} />
        </SimpleList>
      ) : (
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" />
          <EmailField source="email" />
          <TextField source="role" />
        </Datagrid>
      )}
    </List>
  );
};
