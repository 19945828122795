import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { useNotify } from 'react-admin';
import LockResetIcon from '@mui/icons-material/LockReset';
import userHandler from '../../resourceHandlers/userHandler';

export const ResetPasswordButton = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleClick = e => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      notify('Passwords do not match', { type: 'error' });
      return;
    }

    try {
      setLoading(true);
      await userHandler.resetPassword(userId, newPassword);
      notify('Password reset successful', { type: 'success' });
      handleClose();
    } catch (error) {
      notify(error.message, { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={handleClick} startIcon={<LockResetIcon />} size="small">
        Reset Password
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reset User Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Confirm Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            disabled={loading || !newPassword || !confirmPassword}
            color="primary"
          >
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
