import React, { memo } from 'react';
import { useRecordContext } from 'react-admin';

const FormattedField = memo(({ source, formatter }) => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return <span>{formatter?.(record?.[source])}</span>;
});

export default FormattedField;
