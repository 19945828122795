import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useDataProvider,
} from 'react-admin';

import { getProducts } from 'supabase-connect';
import { PRODUCT_TYPE } from '../../../constants';
import { Input, InputAdornment, CircularProgress } from '@mui/material';

import styles from './styles.module.css';
import debounce from 'lodash/debounce';
import UserSelector from './UserSelector';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateEnquiryToolbar from './createEnquiryToolbar';
import ConfigurationModal from '../ConfigurationModal';

// ProductCard component for displaying individual products
const ProductCard = ({ product, onSelect, selected }) => (
  <div className={`${styles.productCard} ${selected ? styles.selected : ''}`}>
    <div
      className={styles.productImage}
      style={{ backgroundImage: `url(${product.image_url})` }}
    />
    <div className={styles.productInfo}>
      <div className={styles.productName}>{product.name}</div>
      <div className={styles.productNumber}>{product.product_number}</div>
      <button
        type="button"
        className={styles.selectButton}
        onClick={() => onSelect(product)}
      >
        {selected ? 'Deselect' : 'Select'}
      </button>
    </div>
  </div>
);

// Main ProductSelector component
const ProductSelector = ({
  selectedProducts,
  setSelectedProducts,
  onChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Fetch products on mount
  useEffect(() => {
    const fetchProducts = async () => {
      const { data: allProducts } = await getProducts();
      setAllProducts(allProducts);
    };
    fetchProducts();
  }, []);

  const openModal = product => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setModalOpen(false);
  };

  const handleSaveConfiguration = (productData, configurations) => {
    setSelectedProducts(prevProducts => {
      const existingProductIndex = prevProducts.findIndex(
        product => product.id === productData?.id
      );

      if (existingProductIndex !== -1) {
        return prevProducts.map((product, index) =>
          index === existingProductIndex
            ? { ...product, selected_configurations: configurations }
            : product
        );
      } else {
        const newProduct = {
          ...productData,
          quantity: 1,
          selected_configurations: configurations,
        };
        return [...prevProducts, newProduct];
      }
    });
  };

  const debouncedFetchProducts = useMemo(
    () =>
      debounce(term => {
        setIsLoading(true);
        const filteredProducts = allProducts.filter(
          product =>
            product.name.toLowerCase().includes(term.toLowerCase()) ||
            product.product_number.toLowerCase().includes(term.toLowerCase())
        );
        setDisplayedProducts(filteredProducts.slice(0, 3));
        setIsLoading(false);
      }, 300),
    [allProducts]
  );

  const fetchProducts = useCallback(() => {
    debouncedFetchProducts(searchTerm);
  }, [debouncedFetchProducts, searchTerm]);

  useEffect(() => {
    if (allProducts) {
      fetchProducts(searchTerm);
    }
  }, [allProducts, searchTerm, fetchProducts]);

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const handleProductSelect = product => {
    const index = selectedProducts.findIndex(p => p.id === product.id);
    let updatedProducts;

    if (index === -1) {
      if (
        product.product_type === PRODUCT_TYPE?.VEHICLE &&
        product.product_configurations
      ) {
        openModal(product);
      } else {
        updatedProducts = [...selectedProducts, { ...product, quantity: 1 }];
        setSelectedProducts(updatedProducts);
        onChange(updatedProducts);
      }
    } else {
      updatedProducts = selectedProducts.filter(p => p.id !== product.id);
      setSelectedProducts(updatedProducts);
      onChange(updatedProducts);
    }
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].quantity = quantity;
    setSelectedProducts(updatedProducts);
    onChange(updatedProducts);
  };

  const handleRemoveProduct = index => {
    const updatedProducts = selectedProducts.filter((_, i) => i !== index);
    setSelectedProducts(updatedProducts);
    onChange(updatedProducts);
  };

  const handleClearAll = () => {
    setSelectedProducts([]);
    onChange([]);
  };

  return (
    <div className={styles.productSelectorContainer}>
      <div className={styles.productSelectionColumn}>
        <div className={styles.searchContainer}>
          <Input
            type="text"
            placeholder="Search for Products"
            value={searchTerm}
            onChange={handleSearchChange}
            className={styles.searchInput}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            fullWidth
          />
        </div>

        <div className={styles.productGrid}>
          {displayedProducts.map(product => (
            <ProductCard
              key={product.id}
              product={product}
              onSelect={handleProductSelect}
              selected={selectedProducts.some(p => p.id === product.id)}
            />
          ))}
        </div>
        {isLoading && <CircularProgress />}
      </div>

      <div className={styles.selectedProductsColumn}>
        <div className={styles.selectedProductHeader}>
          <div className={styles.selectedProductTitle}>Selected Products</div>
          <button
            type="button"
            onClick={handleClearAll}
            color="secondary"
            size="small"
          >
            Clear All
          </button>
        </div>
        <div className={styles.selectedProductContainer}>
          {selectedProducts.map((product, index) => (
            <div key={product.id} className={styles.selectedProductCard}>
              <div className={styles.selectedProductInfo}>
                <div className={styles.selectedProductTop}>
                  <div className={styles.productName}>{product.name}</div>
                  <button
                    onClick={() => handleRemoveProduct(index)}
                    className={styles.removeButton}
                  >
                    <DeleteIcon />
                  </button>
                </div>
                <div className={styles.productNumber}>
                  {product.product_number}
                </div>
              </div>
              <Input
                type="number"
                value={product.quantity}
                onChange={e =>
                  handleQuantityChange(index, parseInt(e.target.value, 10))
                }
                inputProps={{
                  min: 1,
                  step: 1,
                }}
                placeholder="Enter a quantity"
                startAdornment={
                  <InputAdornment position="start">Qty:</InputAdornment>
                }
                className={styles.quantityInput}
              />
            </div>
          ))}
        </div>
        <div className={styles.selectedProductSummary}>
          Total Products: {selectedProducts.length}
        </div>
      </div>

      {/* Modal to handle configuration selection */}
      {selectedProduct && (
        <ConfigurationModal
          open={isModalOpen}
          onClose={closeModal}
          product={selectedProduct}
          onSave={handleSaveConfiguration}
        />
      )}
    </div>
  );
};

export const EnquiryCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [submissionError, setSubmissionError] = useState(null);
  const [userId, setUserId] = useState(null); // State to capture selected user ID

  const handleProductChange = products => {
    setSelectedProducts(products);
    setSubmissionError(null);
  };

  const validateForm = () => {
    if (selectedProducts.length === 0) {
      setSubmissionError(
        'At least one product should be included in the enquiry.'
      );
      return false;
    }
    return true;
  };

  const handleSubmit = async values => {
    if (!validateForm()) {
      return;
    }

    const dataToSubmit = {
      ...values,
      user_id: userId,
      products: selectedProducts,
    };

    try {
      await dataProvider.create('enquiries', { data: dataToSubmit });
      notify('Enquiry created successfully', { type: 'success' });
      redirect('list', 'enquiries');
    } catch (error) {
      notify(`Error: ${error.message}`, { type: 'warning' });
      setSubmissionError('Failed to create the enquiry. Please try again.');
    }
  };

  return (
    <Create {...props}>
      <SimpleForm
        toolbar={
          <CreateEnquiryToolbar
            onSubmit={handleSubmit}
            selectedProducts={selectedProducts}
          />
        }
      >
        <div className={styles.formContainer}>
          <div className={styles.formFields}>
            <UserSelector value={userId} onChange={setUserId} />
          </div>

          <ProductSelector
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            onChange={handleProductChange}
          />

          {submissionError && (
            <div className={styles.errorMessage}>{submissionError}</div>
          )}

          {/* Hidden input to store the selected products data */}
          <TextInput
            source="products"
            style={{ display: 'none' }}
            value={JSON.stringify(selectedProducts)}
          />
        </div>
      </SimpleForm>
    </Create>
  );
};

export default EnquiryCreate;
