import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { defaultTheme } from 'react-admin';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  ...defaultTheme,
  typography: {
    ...defaultTheme.typography,
    fontFamily: ['Roboto', '"Roboto Condensed"', 'sans-serif'].join(','),
  },
  components: {
    ...defaultTheme.components,
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
