import React, { useState } from 'react';
import { useInput } from 'react-admin';
import { formatStatusName } from '../../../common/helpers';
import { Typography, TextField, Button, Grid } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import styles from './styles.module.css';

const ConfigurationSection = ({ source }) => {
  const {
    field: { value: configurations, onChange },
  } = useInput({ source });

  const groupedConfigurations = configurations?.reduce((groups, item) => {
    const category = item.category;
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(item);
    return groups;
  }, {});

  const [newValues, setNewValues] = useState({});

  const handleConfigChange = (id, newValue) => {
    const updatedConfigurations = configurations.map(config =>
      config.id === id ? { ...config, value: newValue } : config
    );
    onChange(updatedConfigurations);
  };

  const handleAddValue = category => {
    const valueToAdd = newValues[category]?.trim();
    if (valueToAdd) {
      const updatedConfigurations = [
        ...configurations,
        {
          id: `${category}-${Date.now()}`,
          isNew: true,
          category,
          value: valueToAdd,
        },
      ];

      // Only update if there was a change
      const hasChanged = updatedConfigurations.some(
        (config, index) =>
          JSON.stringify(config.values) !==
          JSON.stringify(configurations[index]?.value)
      );

      if (hasChanged) {
        onChange(updatedConfigurations);
      }

      setNewValues({ ...newValues, [category]: '' });
    }
  };

  const handleRemoveValue = id => {
    const updatedConfigurations = configurations.filter(
      config => config.id !== id
    );
    onChange(updatedConfigurations);
  };

  return (
    <Grid item xs={12}>
      {configurations.length > 0 && (
        <Typography variant="h6">Edit Configurations</Typography>
      )}

      {groupedConfigurations &&
        Object.keys(groupedConfigurations).map(category => (
          <div key={category}>
            <Typography variant="body1" className={styles.categoryTitle}>
              {formatStatusName(category)}
            </Typography>

            <div className={styles.gridContainer}>
              {groupedConfigurations[category].map(config => (
                <div key={config?.id} className={styles.inputContainer}>
                  <input
                    className={styles.configInput}
                    type="text"
                    placeholder={config.name}
                    value={config.value}
                    onChange={e =>
                      handleConfigChange(config.id, e.target.value)
                    }
                  />

                  {config?.isNew && (
                    <span
                      className={styles.removeIcon}
                      onClick={() => handleRemoveValue(config.id)}
                    >
                      <CloseIcon />
                    </span>
                  )}
                </div>
              ))}
            </div>

            <TextField
              label={`Add ${formatStatusName(category)}`}
              value={newValues[category] || ''}
              onChange={e =>
                setNewValues({ ...newValues, [category]: e.target.value })
              }
              fullWidth
              variant="outlined"
              margin="normal"
            />

            <Button
              onClick={() => handleAddValue(category)}
              variant="contained"
              color="primary"
            >
              Add Value
            </Button>
          </div>
        ))}
    </Grid>
  );
};

export default ConfigurationSection;
