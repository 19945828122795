import React from 'react';
import { CircularProgress, Box, CssBaseline, useTheme } from '@mui/material';
import {
  DateField,
  EditButton,
  EmailField,
  ImageField,
  NumberField,
  ReferenceField,
  ShowButton,
  TextField,
  Title,
} from 'react-admin';
import { DATE_FORMAT_OPTIONS } from '../../constants';
import { useFetchResourceData } from '../../hooks/useFetchResourceData';
import { FormatStatusField } from '../common-component/FormatStatusField';

import StatsCardSection from './StatsCardSection';
import ManagementSection from './ManagementSection';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const Dashboard = ({ canViewUsers, canViewEnquiries, canViewProducts }) => {
  const theme = useTheme();

  const { total: totalEnquiries, isLoading: isLoadingEnquiries } =
    useFetchResourceData('enquiries');

  const { total: totalUsers, isLoading: isLoadingUsers } =
    useFetchResourceData('users');

  const { total: totalProducts, isLoading: isLoadingProducts } =
    useFetchResourceData('products');

  const { total: totalQuotations, isLoading: isLoadingQuotations } =
    useFetchResourceData('quotations');

  if (
    isLoadingEnquiries ||
    isLoadingUsers ||
    isLoadingProducts ||
    isLoadingQuotations
  ) {
    return <CircularProgress />;
  }

  const statCards = [
    canViewUsers && {
      key: 'users',
      icon: <PeopleIcon />,
      title: 'Total Users',
      value: totalUsers || 0,
      color: theme.palette.primary.main,
    },
    canViewProducts && {
      key: 'products',
      icon: <ShoppingCartIcon />,
      title: 'Total Products',
      value: totalProducts || 0,
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.secondary.dark
          : theme.palette.secondary.main,
    },
    canViewEnquiries && {
      key: 'enquiries',
      icon: <ReceiptIcon />,
      title: 'Total Enquiries',
      value: totalEnquiries || 0,
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.success.dark
          : theme.palette.success.main,
    },
    canViewEnquiries && {
      key: 'quotations',
      icon: <RequestQuoteIcon />,
      title: 'Total Quotations',
      value: totalQuotations,
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.warning.dark
          : theme.palette.warning.main,
    },
  ].filter(Boolean);

  return (
    <>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, padding: 3 }}>
        <Title title="Orbit Auto World" />

        <StatsCardSection statCards={statCards} />

        {/* Render management sections */}
        {canViewEnquiries && (
          <ManagementSection title="Enquiries Management" resource="enquiries">
            <TextField
              key="enquiry_number"
              source="enquiry_number"
              sortable={false}
            />

            <ReferenceField
              key="user_id"
              source="user_id"
              reference="user_details"
              sortable={false}
            >
              <TextField key="email" source="email" sortable={false} />
            </ReferenceField>

            <FormatStatusField key="status" source="status" sortable={false} />

            <DateField
              source="created_at"
              label="Created At"
              showTime={true}
              options={DATE_FORMAT_OPTIONS}
              sortable={false}
            />

            <ShowButton key="show_enquiry" />
          </ManagementSection>
        )}

        {canViewUsers && (
          <ManagementSection title="User Management" resource="users">
            <TextField key="id" source="id" sortable={false} />

            <EmailField key="email" source="email" sortable={false} />

            <TextField key="role" source="role" sortable={false} />
          </ManagementSection>
        )}

        {canViewProducts && (
          <ManagementSection title="Product Management" resource="products">
            <TextField key="product_number" source="product_number" />

            <TextField key="name" source="name" sortable={false} />

            <NumberField
              key="price"
              source="price"
              emptyText="N/A"
              options={{ style: 'currency', currency: 'INR' }}
              sortable={false}
            />

            <ImageField
              key="image_url"
              source="image_url"
              title="image_alt"
              sx={{
                '& img': {
                  maxWidth: 50,
                  maxHeight: 50,
                  objectFit: 'cover',
                  borderRadius: '50%',
                  border: `1px solid ${theme.palette.divider}`,
                },
              }}
              sortable={false}
            />

            <EditButton key="edit_product" sortable={false} />

            <ShowButton key="show_product" sortable={false} />
          </ManagementSection>
        )}
      </Box>
    </>
  );
};

export default Dashboard;
