import React, { memo } from 'react';
import { useListContext } from 'react-admin';
import ProductCard from './ProductCard.js';
import SortableButton from './SortableButton.js';
import { PRODUCT_SORT_FIELDS } from '../../../constants';
import styles from './styles.module.css';

const MobileList = memo(() => {
  const { data, isLoading } = useListContext();

  if (isLoading) return null;

  return (
    <div className={styles?.mobile_list}>
      <div className={styles?.sort_buttons}>
        {PRODUCT_SORT_FIELDS?.map(({ field, label }) => (
          <SortableButton key={field} field={field} label={label} />
        ))}
      </div>

      {data?.map(record => (
        <ProductCard key={record?.id} record={record} />
      ))}
    </div>
  );
});

export default MobileList;
