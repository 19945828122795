import React, { useState } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  useShowController,
  Link,
  Button,
  TopToolbar,
} from 'react-admin';
import {
  CardContent,
  Grid,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
} from '@mui/material';
import { formatStatusName } from '../../../common/helpers';
import {
  DATE_FORMAT_OPTIONS,
  ENQUIRY_STATUS,
  PRODUCT_TYPE,
} from '../../../constants';

import EnquiryQuotation from '../EnquiryQuotation';

import styles from './styles.module.css';

const EnquiryStatus = ({ status }) => {
  const getStatusColor = status => {
    switch (status) {
      case ENQUIRY_STATUS?.SUBMITTED:
        return '#FFA000';
      case ENQUIRY_STATUS?.QUOTATION_GENERATED:
        return '#4CAF50';
      default:
        return '#9E9E9E';
    }
  };

  return (
    <Chip
      label={formatStatusName(status)}
      style={{
        backgroundColor: getStatusColor(status),
        color: '#FFFFFF',
      }}
    />
  );
};

export const EnquiryShow = props => {
  const [showQuotation, setShowQuotation] = useState(false);
  const { record } = useShowController(props);

  if (!record) return null;

  const EnquiryShowActions = () => <TopToolbar />;

  return (
    <Show {...props} actions={<EnquiryShowActions />}>
      <SimpleShowLayout className={styles.root}>
        <div className={styles.card}>
          <Box className={styles.cardTitle}>
            <Typography variant="h6">Enquiry Details</Typography>
          </Box>

          <CardContent className={styles.cardContent}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle2">Enquiry Number</Typography>

                <Typography variant="body1">
                  <TextField source="enquiry_number" />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle2">User Email</Typography>

                <Typography variant="body1">
                  <TextField source="user_details.email" />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle2">Status</Typography>
                <EnquiryStatus status={record?.status} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle2">Created At</Typography>

                <Typography variant="body1">
                  <DateField
                    source="created_at"
                    showTime
                    options={DATE_FORMAT_OPTIONS}
                  />
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </div>

        <div className={`fade-${showQuotation ? 'enter' : 'exit'}`}>
          {showQuotation ? (
            <EnquiryQuotation id={record.id} />
          ) : (
            <>
              <div className={styles.card}>
                <Box className={styles.cardTitle}>
                  <Typography variant="h6">Products</Typography>
                </Box>

                <div className={styles.cardContent}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={styles.tableCell}>
                          Product Number
                        </TableCell>

                        <TableCell className={styles.tableCell}>
                          Product Name
                        </TableCell>

                        <TableCell className={styles.tableCell}>
                          Product Description
                        </TableCell>

                        <TableCell className={styles.tableCell}>
                          Product Type
                        </TableCell>

                        <TableCell className={styles.tableCell} align="right">
                          Quantity
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {record?.enquiry_products?.map((item, index) => (
                        <React.Fragment key={item?.products?.id || index}>
                          <TableRow>
                            <TableCell
                              style={{
                                color: 'inherit',
                                minWidth: 100,
                                borderBottom:
                                  item?.products?.product_type ===
                                    PRODUCT_TYPE?.VEHICLE && 'none',
                              }}
                            >
                              {item?.products?.id ? (
                                <Link
                                  to={`/products/${item?.products?.id}/show`}
                                >
                                  {item?.products?.product_number}
                                </Link>
                              ) : (
                                <Typography>Custom Product</Typography>
                              )}
                            </TableCell>

                            <TableCell
                              style={{
                                color: 'inherit',
                                minWidth: 100,
                                borderBottom:
                                  item?.products?.product_type ===
                                    PRODUCT_TYPE?.VEHICLE && 'none',
                              }}
                            >
                              <Typography>
                                {item?.products?.name || 'N/A'}
                              </Typography>
                            </TableCell>

                            <TableCell
                              style={{
                                color: 'inherit',
                                minWidth: 100,
                                maxWidth: 200,
                                borderBottom:
                                  item?.products?.product_type ===
                                    PRODUCT_TYPE?.VEHICLE && 'none',
                              }}
                            >
                              <Typography>
                                {item?.product_description ||
                                  item?.products?.description}
                              </Typography>
                            </TableCell>

                            <TableCell
                              style={{
                                color: 'inherit',
                                minWidth: 100,
                                borderBottom:
                                  item?.products?.product_type ===
                                    PRODUCT_TYPE?.VEHICLE && 'none',
                              }}
                            >
                              <Typography>
                                {formatStatusName(item?.products?.product_type)}
                              </Typography>
                            </TableCell>

                            <TableCell
                              style={{
                                color: 'inherit',
                                minWidth: 100,
                                borderBottom:
                                  item?.products?.product_type ===
                                    PRODUCT_TYPE?.VEHICLE && 'none',
                              }}
                              align="right"
                            >
                              <Typography>{item?.quantity}</Typography>
                            </TableCell>
                          </TableRow>

                          {item?.products?.product_type ===
                            PRODUCT_TYPE?.VEHICLE &&
                            item?.enquiry_configurations?.length > 0 && (
                              <TableRow>
                                <TableCell
                                  colSpan={5}
                                  style={{
                                    paddingTop: 0,
                                    paddingBottom: 16,
                                    paddingLeft: 16,
                                  }}
                                >
                                  <Box mt={1} mb={2}>
                                    <Typography
                                      variant="subtitle1"
                                      gutterBottom
                                    >
                                      Vehicle Configuration:
                                    </Typography>

                                    <Typography
                                      variant="body2"
                                      className={styles.configOptions}
                                    >
                                      {item.enquiry_configurations.map(
                                        (config, configIndex) => (
                                          <React.Fragment key={configIndex}>
                                            <span
                                              className={styles.configOption}
                                            >
                                              <span
                                                className={styles.configLabel}
                                              >
                                                {formatStatusName(
                                                  config.configuration_options
                                                    .category
                                                )}
                                                :
                                              </span>{' '}
                                              {
                                                config.configuration_options
                                                  .value
                                              }
                                            </span>
                                            {configIndex <
                                              item.enquiry_configurations
                                                .length -
                                                1 && (
                                              <span
                                                className={styles.separator}
                                              >
                                                |
                                              </span>
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>

              <Box className={styles.buttonContainer}>
                <Button
                  onClick={() => setShowQuotation(true)}
                  variant="contained"
                  color="primary"
                  className={styles.button}
                  disabled={record?.status !== ENQUIRY_STATUS?.SUBMITTED}
                >
                  Generate Quotation
                </Button>

                {record?.status !== ENQUIRY_STATUS?.SUBMITTED && (
                  <Link
                    to={`/quotations/${record?.quotation_id}/show`}
                    className={styles.linkButton}
                  >
                    <Typography variant="button">View Quotation</Typography>
                  </Link>
                )}
              </Box>
            </>
          )}
        </div>
      </SimpleShowLayout>
    </Show>
  );
};
