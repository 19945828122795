import { checkUserRole } from './authHandler';
import { supabase } from '../utils/supabase';

// Helper function to validate status
const isValidStatus = status => {
  const validStatuses = ['ALL', 'ACTIVE', 'INACTIVE'];
  return validStatuses.includes(status);
};

// Helper function to validate product type
const isValidProductType = type => {
  const validTypes = ['ALL', 'VEHICLE', 'VEHICLE_PART'];
  return validTypes.includes(type);
};

export const getList = async (resource, params, baseDataProvider) => {
  if (resource === 'users') {
    try {
      const {
        data: { user },
        error: userError,
      } = (await supabase.auth?.getUser()) ?? {};

      if (userError || !user) {
        // eslint-disable-next-line no-console
        console.error('Error fetching authenticated user:', userError);
        return { data: [], total: 0 };
      }

      const { data: currentUserDetails, error: currentUserDetailsError } =
        await supabase
          .from('user_details')
          .select('role')
          .eq('id', user?.id)
          .single();

      if (
        currentUserDetailsError &&
        currentUserDetailsError.code !== 'PGRST116'
      ) {
        // eslint-disable-next-line no-console
        console.error(
          'Error fetching current user details:',
          currentUserDetailsError
        );
        return { data: [], total: 0 };
      }

      const userRole = currentUserDetails?.role ?? 'CUSTOMER';

      if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
        // eslint-disable-next-line no-console
        console.log('Non-admin user attempted to access user list');
        return Promise.reject({
          message:
            'Access denied: You do not have permission to view the user list.',
        });
      }

      const search = params.filter?.q ?? '';

      let query = supabase.from('user_details').select('*', { count: 'exact' });

      if (search) {
        query = query.ilike('email', `%${search}%`);
      }

      const {
        data: userDetails,
        error: detailsError,
        count,
      } = await query
        .range(
          (params.pagination?.page - 1) * (params.pagination?.perPage ?? 0),
          params.pagination?.page * (params.pagination?.perPage ?? 0) - 1
        )
        .order(params.sort?.field, {
          ascending: params.sort?.order === 'ASC',
        });

      if (detailsError) {
        // eslint-disable-next-line no-console
        console.error('Error fetching user details:', detailsError);
        return { data: [], total: 0 };
      }

      return {
        data: userDetails,
        total: count || 0,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in getList for users:', error);
      return { data: [], total: 0 };
    }
  } else if (resource === 'currencies') {
    try {
      const { data, error, count } = await supabase
        .from('currencies')
        .select('*', { count: 'exact' })
        .range(
          (params.pagination?.page - 1) * (params.pagination?.perPage ?? 0),
          params.pagination?.page * (params.pagination?.perPage ?? 0) - 1
        )
        .order(params.sort?.field || 'name', {
          ascending: params.sort?.order === 'ASC',
        });

      if (error) {
        throw new Error(error.message);
      }

      return {
        data,
        total: count || 0,
      };
    } catch (error) {
      return { data: [], total: 0 };
    }
  } else if (resource === 'products') {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: "Access denied: You don't have permission to view products",
      });
    }

    const { page, perPage } = params.pagination ?? { page: 1, perPage: 10 };
    const search = params.filter?.q ?? '';

    const start = (page - 1) * perPage;
    const end = start + perPage - 1;

    let query = supabase
      .from('products')
      .select(
        `
      *,
      product_configurations (
        configuration_options (
          id,
          value,
          category
        )
      )
      `,
        { count: 'exact' }
      )
      .range(start, end)
      .order(params.sort?.field || 'created_at', {
        ascending: params.sort?.order === 'ASC',
      });

    if (params.filter?.status) {
      const status = params.filter.status.toUpperCase();
      if (!isValidStatus(status)) {
        throw new Error('Invalid status parameter');
      }
      if (status !== 'ALL') {
        query = query.eq('status', status);
      }
    }

    if (params.filter?.product_type) {
      const productType = params.filter.product_type.toUpperCase();
      if (!isValidProductType(productType)) {
        throw new Error('Invalid product type parameter');
      }
      if (productType !== 'ALL') {
        query = query.eq('product_type', productType);
      }
    }

    if (params.filter?.q) {
      const sanitizedSearch = search
        .trim()
        .replace(/[^a-zA-Z0-9\s-]/g, '')
        .substring(0, 100);

      if (sanitizedSearch) {
        query = query.or(
          `name.ilike.%${sanitizedSearch}%,product_number.ilike.%${sanitizedSearch}%,description.ilike.%${sanitizedSearch}%`
        );
      }
    }

    const { data, error, count } = await query;

    if (error) {
      throw new Error(error.message);
    }

    // Format the product configurations
    const formattedData = data.map(product => {
      const formattedConfigurations = {};

      if (product.product_configurations) {
        product.product_configurations.forEach(config => {
          const type = config?.configuration_options?.category;
          const value = config?.configuration_options?.value;
          const optionId = config?.configuration_options?.id;
          if (!formattedConfigurations[type]) {
            formattedConfigurations[type] = [];
          }
          formattedConfigurations[type].push({ id: optionId, value });
        });
      }

      return { ...product, product_configurations: formattedConfigurations };
    });

    return {
      data: formattedData,
      total: count,
    };
  } else if (resource === 'enquiries' || resource === 'quotations') {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: `Access denied: You don't have permission to view ${resource}`,
      });
    }

    const search = params?.filter?.q ?? '';
    const status = params?.filter?.status;

    const getSelectQuery = resource => {
      if (resource === 'quotations') {
        return `
          *,
          currency_id (
            code,
            name
          )
        `;
      }
      return '*';
    };

    let query = supabase
      .from(resource)
      .select(getSelectQuery(resource), { count: 'exact' })
      .range(
        (params.pagination?.page - 1) * (params.pagination?.perPage ?? 0),
        params.pagination?.page * (params.pagination?.perPage ?? 0) - 1
      )
      .order(params.sort?.field, {
        ascending: params.sort?.order === 'ASC',
      });

    let conditions = [];

    if (resource === 'enquiries') {
      if (search) {
        conditions.push(`enquiry_number.ilike.%${search}%`);
      }
      if (status && status !== '') {
        conditions.push(`status.eq.${status}`);
      }
    } else if (resource === 'quotations') {
      if (search) {
        conditions.push(`quotation_number.ilike.%${search}%`);
      }
      if (status && status !== '') {
        conditions.push(`status.eq.${status}`);
      }
    }

    if (conditions.length > 0) {
      query = query.or(conditions.join(','));
    }

    const { data, error, count } = await query;

    if (error) {
      // eslint-disable-next-line no-console
      console.error(`Error fetching ${resource}:`, error);
      return { data: [], total: 0 };
    }

    return {
      data,
      total: count || 0,
    };
  } else if (resource === 'invoices') {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: `Access denied: You don't have permission to view ${resource}`,
      });
    }

    const search = params?.filter?.q ?? '';
    const status = params?.filter?.status;

    let query = supabase
      .from(resource)
      .select(
        `
        *,
        quotation_id (
          *,
          currency_id (
            code,
            name
          )
        )
        `,
        { count: 'exact' }
      )
      .range(
        params.pagination.perPage * (params.pagination.page - 1),
        params.pagination.perPage * params.pagination.page - 1
      )
      .order(params.sort?.field, {
        ascending: params.sort?.order === 'ASC',
      });

    let conditions = [];

    if (search) {
      conditions.push(`invoice_number.ilike.%${search}%`);
    }
    if (status && status !== '') {
      conditions?.push(`status.eq.${status}`);
    }

    if (conditions.length > 0) {
      query = query?.or(conditions.join(','));
    }

    const { data, error, count } = await query;

    if (error) {
      throw new Error(error?.message);
    }

    return {
      data: data || [],
      total: count || 0,
    };
  } else if (resource === 'payments') {
    const search = params?.filter?.q ?? '';
    const confirmed = params?.filter?.confirmed;

    let query = supabase
      .from('payments')
      .select(
        `
          *,
          invoices (
            invoice_number,
            quotations!quotations_invoice_id_fkey (
              *,
              enquiries!quotations_enquiry_id_fkey (
                user_id,
                user_details (
                  email
                )
              )
            )
          )
        `,
        {
          count: 'exact',
        }
      )
      .range(
        (params.pagination?.page - 1) * (params.pagination?.perPage ?? 0),
        params.pagination?.page * (params.pagination?.perPage ?? 0) - 1
      )
      .order(params.sort?.field, {
        ascending: params.sort?.order === 'ASC',
      });

    let conditions = [];

    if (search) {
      conditions.push(`invoices.invoice_number.ilike.%${search}%`);
      conditions.push(
        `invoices.quotations.enquiries.user_details.email.ilike.%${search}%`
      );
    }

    if (confirmed !== undefined) {
      conditions.push(`confirmed.eq.${confirmed}`);
    }

    if (conditions.length > 0) {
      query = query.or(conditions.join(','));
    }

    const { data, error, count } = await query;

    if (error) {
      return { data: [], total: 0 };
    }

    return {
      data: data.map(record => ({
        ...record,
        id: record.id,
        invoice_number: record.invoices?.invoice_number,
        user_email:
          record.invoices?.quotations?.[0]?.enquiries?.user_details?.email ||
          null,
        payment_proofs: record.file_path,
      })),
      total: count || 0,
    };
  } else {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: `Access denied: You don't have permission to view ${resource}`,
      });
    }
  }

  return baseDataProvider.getList(resource, params);
};
