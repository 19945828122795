import React from 'react';
import { useFormik } from 'formik';
import { useLogin, useNotify } from 'react-admin';
import { LoginFormikValidator } from '../../common/formik-validator/LoginFormik';

import loginContentParser from '../../common/parsers/loginContentParser';

import './login.css';

const Login = () => {
  const login = useLogin();
  const notify = useNotify();

  const formikLogin = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validateOnMount: true,

    validate: values =>
      LoginFormikValidator(values, loginContentParser?.formFields),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        const dataPayload = {
          username: values.email,
          password: values.password,
        };

        await login(dataPayload);
        notify('Login successful', { type: 'success' });
      } catch (error) {
        notify(error.message || 'An unexpected error occurred');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-diagonal" />

        <div className="login-content">
          <div className="login-left-side">
            <h2 className="login-title">
              {loginContentParser?.formHeading}
              <div className="login-title-underline" />
            </h2>

            <form onSubmit={formikLogin.handleSubmit}>
              <div className="login-input-container">
                <input
                  className={`login-input ${
                    formikLogin.touched.email && formikLogin.errors.email
                      ? 'error'
                      : ''
                  }`}
                  type="text"
                  name="email"
                  placeholder="Email"
                  autoComplete="username"
                  {...formikLogin.getFieldProps('email')}
                />
                {formikLogin.touched.email && formikLogin.errors.email && (
                  <div className="error-message">
                    {formikLogin.errors.email}
                  </div>
                )}
              </div>

              <div className="login-input-container">
                <input
                  className={`login-input ${
                    formikLogin.touched.password && formikLogin.errors.password
                      ? 'error'
                      : ''
                  }`}
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  {...formikLogin.getFieldProps('password')}
                />
                {formikLogin.touched.password &&
                  formikLogin.errors.password && (
                    <div className="error-message">
                      {formikLogin.errors.password}
                    </div>
                  )}
              </div>

              <button
                className="login-button"
                type="submit"
                disabled={formikLogin.isSubmitting || !formikLogin.isValid}
              >
                {formikLogin.isSubmitting
                  ? loginContentParser?.buttonLoadingText
                  : loginContentParser?.submitButtonText}
              </button>
            </form>

            <p className="login-signup-text">
              {loginContentParser?.forgotPasswordText}{' '}
              {loginContentParser?.forgotPasswordSubtext}
            </p>
          </div>

          <div className="login-right-side">
            <img src="/logos/oaw-logo.svg" alt="Logo" className="login-logo" />

            <h2 className="login-welcome-title">
              {loginContentParser?.welcomeTitle}
            </h2>

            <p className="login-welcome-text">
              {loginContentParser?.welcomeSubtitle}
            </p>

            <p className="login-welcome-text">
              {' '}
              {loginContentParser?.welcomeSubtext}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
