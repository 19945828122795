import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  useNotify,
  Toolbar,
  SaveButton,
  useRefresh,
  useRecordContext,
  useGetOne,
  useDataProvider,
} from 'react-admin';
import { Box } from '@mui/material';
import { ResetPasswordButton } from './ResetPasswordButton';

import CurrencySelector from '../../components/CurrencySelector';
import CustomSelectInput from '../../components/common-component/CustomSelectInput';

const EditActions = props => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable />
  </Toolbar>
);

const EditDefaultCurrency = ({ onCurrencyChange }) => {
  const record = useRecordContext();
  const { data: currency } = useGetOne('currencies', {
    id: record?.default_currency,
  });

  const [selectedCurrency, setSelectedCurrency] = React.useState(currency);

  React.useEffect(() => {
    if (record && currency) {
      setSelectedCurrency(currency);
      onCurrencyChange(currency);
    }
  }, [record, currency, onCurrencyChange]);

  return (
    <CurrencySelector
      userId={record.id}
      selectedCurrency={selectedCurrency}
      onCurrencyChange={currency => {
        setSelectedCurrency(currency);
        onCurrencyChange(currency);
      }}
    />
  );
};

export const UserEdit = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [selectedCurrency, setSelectedCurrency] = React.useState(null);

  const handleSubmit = async data => {
    try {
      await dataProvider.update('users', { data });
      onSuccess();
    } catch (error) {
      notify('Error updating user', { type: 'error' });
    }
  };

  const onSuccess = () => {
    notify('User updated successfully', { type: 'success' });
    refresh();
  };

  return (
    <Edit {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm
        toolbar={<EditActions />}
        defaultValues={{ selectedCurrency }}
        onSubmit={handleSubmit}
      >
        <TextInput source="id" InputProps={{ readOnly: true }} />

        <TextInput source="email" InputProps={{ readOnly: true }} />

        <Box display="flex" width="100%" gap={2}>
          <CustomSelectInput
            source="role"
            defaultValue="CUSTOMER"
            choices={[
              { id: 'ADMIN', name: 'Admin' },
              { id: 'SALES_MANAGER', name: 'Sales Manager' },
              { id: 'CUSTOMER', name: 'Customer' },
            ]}
          />

          <EditDefaultCurrency onCurrencyChange={setSelectedCurrency} />
        </Box>

        <ResetPasswordButton />
      </SimpleForm>
    </Edit>
  );
};
