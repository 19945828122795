import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
  useShowController,
  useGetOne,
  Link,
} from 'react-admin';
import {
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Box,
} from '@mui/material';

import InvoiceActions from '../InvoiceActions';

import { DATE_FORMAT_OPTIONS, PRODUCT_TYPE } from '../../../constants';
import { formFields } from '../../../common/content/InvoiceContent';
import { formatCurrency, formatStatusName } from '../../../common/helpers';

import styles from './styles.module.css';
import { styled } from '@mui/material/styles';

const useStyles = styled(theme => ({
  root: {
    '& ?.MuiPaper-root': {
      backgroundColor: '#fff',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0?.1)',
      borderRadius: '8px',
      overflow: 'hidden',
    },
  },
  card: {
    marginBottom: theme?.spacing(3),
  },
  cardTitle: {
    borderBottom: '1px solid #e0e0e0',
    padding: theme?.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  cardContent: {
    padding: theme?.spacing(3),
  },
  table: {
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
      backgroundColor: 'inherit !important',
      color: 'inherit !important',
    },
    '& .MuiTableCell-body': {
      color: 'inherit !important',
    },
    '& .MuiTableRow-root': {
      '&.MuiTableRow-head': {
        backgroundColor: 'inherit !important',
      },
      '&.MuiTableRow-body': {
        backgroundColor: 'inherit !important',
      },
    },
  },

  chip: {
    fontWeight: 'bold',
  },
}));

const QuotationStatus = ({ status }) => {
  const classes = useStyles();
  const getStatusColor = status => {
    switch (status) {
      case 'CUSTOMER_PENDING_REVIEW':
        return '#FFA000';
      case 'CUSTOMER_ACCEPTED':
        return '#4CAF50';
      case 'CUSTOMER_REJECTED':
        return '#F44336';
      case 'CUSTOMER_PARTIALLY_ACCEPTED':
        return '#2196F3';
      default:
        return '#9E9E9E';
    }
  };

  return (
    <Chip
      label={formatStatusName(status)}
      className={classes?.chip}
      style={{
        backgroundColor: getStatusColor(status),
        color: '#FFFFFF',
      }}
    />
  );
};

export const QuotationShow = props => {
  const classes = useStyles();

  const { record } = useShowController(props);
  const { data: userData } = useGetOne(
    'users',
    { id: record?.created_by },
    { enabled: !!record?.created_by }
  );

  const { data: enquiryData } = useGetOne('enquiries', {
    id: record?.enquiry_id,
  });

  const { data: currency } = useGetOne('currencies', {
    id: record?.currency_id,
  });

  const grandTotal = record?.items?.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const renderConfigurationOptions = product => {
    const configOptions = product.configurations;

    if (!configOptions || configOptions.length === 0) return null;

    return (
      <TableRow>
        <TableCell colSpan={7} className={styles.configCell}>
          <Box className={styles.configBox}>
            <Typography variant="subtitle2" className={styles.configTitle}>
              Vehicle Configuration:
            </Typography>

            <Typography variant="body2" className={styles.configOptions}>
              {configOptions.map((option, index) => (
                <span key={option.id} className={styles.configOption}>
                  <span className={styles.configLabel}>
                    {formatStatusName(option.category)}:
                  </span>{' '}
                  {option.value}
                  {index < configOptions.length - 1 ? ' | ' : ''}
                </span>
              ))}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Show {...props}>
      <SimpleShowLayout className={classes.root}>
        {/* Quotation Details */}
        <div
          className={`bg-white overflow-hidden ${classes.card}`}
          style={{
            padding: 16,
            borderRadius: '4px',
            boxShadow:
              '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
          }}
        >
          <Box className={classes?.cardTitle}>
            <Typography variant="h6">Quotation Details</Typography>
          </Box>

          <CardContent className={classes?.cardContent}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Quotation Number
                </Typography>

                <Typography variant="body1">
                  {record?.quotation_number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Status
                </Typography>

                <QuotationStatus status={record?.status} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Total Amount
                </Typography>

                <Typography variant="body1">
                  {formatCurrency(record?.quotation_amount, currency?.code)}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Enquiry
                </Typography>

                <ReferenceField
                  source="enquiry_id"
                  reference="enquiries"
                  link="show"
                >
                  <TextField source="enquiry_number" />
                </ReferenceField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Created At
                </Typography>

                <DateField
                  source="created_at"
                  label="Created At"
                  showTime={true}
                  options={DATE_FORMAT_OPTIONS}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Last Updated
                </Typography>

                <DateField
                  source="updated_at"
                  label="Updated At"
                  showTime={true}
                  options={DATE_FORMAT_OPTIONS}
                />
              </Grid>
            </Grid>
          </CardContent>
        </div>

        <div
          className={`bg-white overflow-hidden ${classes.card}`}
          style={{
            padding: 16,
            borderRadius: '4px',
            boxShadow:
              '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
          }}
        >
          <Box className={classes?.cardTitle}>
            <Typography variant="h6">Quotation Items</Typography>
          </Box>

          <div className={classes?.cardContent}>
            <Table className={classes?.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: 'inherit', minWidth: 100 }}>
                    Product Number
                  </TableCell>

                  <TableCell style={{ color: 'inherit', minWidth: 100 }}>
                    Product Name
                  </TableCell>

                  <TableCell style={{ color: 'inherit', minWidth: 100 }}>
                    Product Description
                  </TableCell>

                  {record?.status !== 'CUSTOMER_PENDING_REVIEW' && (
                    <TableCell style={{ color: 'inherit', minWidth: 100 }}>
                      Approval Status
                    </TableCell>
                  )}

                  <TableCell
                    style={{ color: 'inherit', minWidth: 100 }}
                    align="right"
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    style={{ color: 'inherit', minWidth: 100 }}
                    align="right"
                  >
                    Price / Unit
                  </TableCell>
                  <TableCell
                    style={{ color: 'inherit', minWidth: 100 }}
                    align="right"
                  >
                    Subtotal
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {record?.products?.map(item => (
                  <React.Fragment>
                    <TableRow key={item?.id}>
                      <TableCell
                        style={{
                          color: 'inherit',
                          minWidth: 100,
                          borderBottom:
                            item?.product_type === PRODUCT_TYPE?.VEHICLE &&
                            'none',
                        }}
                      >
                        {item?.product_number ? (
                          <Link to={`/products/${item?.id}/show`}>
                            {item?.product_number}
                          </Link>
                        ) : (
                          <p to={`/products/${item?.id}/show`}>
                            Custom Product
                          </p>
                        )}
                      </TableCell>

                      <TableCell
                        style={{
                          color: 'inherit',
                          minWidth: 100,
                          borderBottom:
                            item?.product_type === PRODUCT_TYPE?.VEHICLE &&
                            'none',
                        }}
                      >
                        {item?.name || 'N/A'}
                      </TableCell>

                      <TableCell
                        style={{
                          color: 'inherit',
                          minWidth: 100,
                          borderBottom:
                            item?.product_type === PRODUCT_TYPE?.VEHICLE &&
                            'none',
                        }}
                      >
                        {item?.description ||
                          item?.product_description ||
                          'N/A'}
                      </TableCell>

                      {record?.status !== 'CUSTOMER_PENDING_REVIEW' && (
                        <TableCell
                          style={{
                            color: 'inherit',
                            minWidth: 100,
                            borderBottom:
                              item?.product_type === PRODUCT_TYPE?.VEHICLE &&
                              'none',
                          }}
                        >
                          {item?.user_selected && item?.user_selected ? (
                            <Chip
                              label="Selected"
                              className={classes?.chip}
                              style={{
                                backgroundColor: '#4CAF50',
                                color: '#FFFFFF',
                              }}
                            />
                          ) : (
                            <Chip
                              label="Rejected"
                              className={classes?.chip}
                              style={{
                                backgroundColor: '#F44336',
                                color: '#FFFFFF',
                              }}
                            />
                          )}
                        </TableCell>
                      )}

                      <TableCell
                        style={{
                          color: 'inherit',
                          minWidth: 100,
                          borderBottom:
                            item?.product_type === PRODUCT_TYPE?.VEHICLE &&
                            'none',
                        }}
                        align="right"
                      >
                        {item?.quantity}
                      </TableCell>

                      <TableCell
                        style={{
                          color: 'inherit',
                          minWidth: 100,
                          borderBottom:
                            item?.product_type === PRODUCT_TYPE?.VEHICLE &&
                            'none',
                        }}
                        align="right"
                      >
                        {formatCurrency(item?.price, currency?.code)}
                      </TableCell>

                      <TableCell
                        style={{
                          color: 'inherit',
                          minWidth: 100,
                          borderBottom:
                            item?.product_type === PRODUCT_TYPE?.VEHICLE &&
                            'none',
                        }}
                        align="right"
                      >
                        {formatCurrency(
                          item?.price * item?.quantity,
                          currency?.code
                        )}
                      </TableCell>
                    </TableRow>

                    {item?.product_type === PRODUCT_TYPE?.VEHICLE &&
                      renderConfigurationOptions(item)}
                  </React.Fragment>
                ))}

                <TableRow>
                  <TableCell
                    style={{ color: 'inherit', minWidth: 100 }}
                    colSpan={
                      record?.status !== 'CUSTOMER_PENDING_REVIEW' ? 6 : 5
                    }
                    align="right"
                  >
                    <strong>Grand Total:</strong>
                  </TableCell>
                  <TableCell
                    style={{ color: 'inherit', minWidth: 100 }}
                    align="right"
                  >
                    <strong>
                      {formatCurrency(grandTotal, currency?.code)}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>

        <div className={styles.bottomContainer}>
          <div className={styles.bottomLeftContainer}>
            <Typography variant="subtitle2" gutterBottom>
              Created By
            </Typography>

            <TextField
              source="email"
              record={userData}
              label="Created By Email"
            />
          </div>

          <InvoiceActions
            record={record}
            enquiryData={enquiryData}
            formFields={formFields}
          />
        </div>
      </SimpleShowLayout>
    </Show>
  );
};

export default QuotationShow;
