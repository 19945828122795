import React from 'react';
import { useInput, FieldTitle } from 'react-admin';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const CustomSelectInput = props => {
  const { choices, label, source, defaultValue, onChange, ...rest } = props;

  const {
    field,
    fieldState: { error },
  } = useInput({
    source,
    defaultValue,
    ...rest,
  });

  return (
    <FormControl
      fullWidth
      variant="outlined"
      sx={{
        minWidth: '200px',
        maxWidth: '300px',
        '& .RaFilterForm-filterFormInput': {
          marginLeft: '1rem',
          marginBottom: '1rem',
        },
      }}
    >
      <InputLabel>
        {label || <FieldTitle label={label} source={source} />}
      </InputLabel>

      <Select
        {...field}
        label={label || <FieldTitle label={label} source={source} />}
        onChange={e => {
          field?.onChange(e);
          if (onChange) onChange(e);
        }}
        displayEmpty
        error={!!error}
      >
        {choices?.map(choice => (
          <MenuItem key={choice?.id} value={choice?.id}>
            {choice?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectInput;
