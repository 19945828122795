import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ShowButton,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  useRedirect,
  Button,
  useGetOne,
} from 'react-admin';
import { DATE_FORMAT_OPTIONS } from '../../../constants';
import { formatStatusName } from '../../../common/helpers';
import { FormatStatusField } from '../../../components/common-component/FormatStatusField';

import EnquiryFilter from './EnquiryFilter';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import styles from './styles.module.css';

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const EnquiryCard = ({ record }) => {
  const { data: userDetails } = useGetOne('user_details', {
    id: record.user_id,
  });

  const redirect = useRedirect();

  const handleClick = () => {
    redirect('show', 'enquiries', record.id);
  };

  return (
    <div className={styles.enquiry_card} onClick={handleClick}>
      <div className={styles.enquiry_field}>
        <span className={styles.field_label}>Enquiry Number:</span>

        <span className={styles.field_value}>{record.enquiry_number}</span>
      </div>
      <div className={styles.enquiry_field}>
        <span className={styles.field_label}>User Email:</span>

        <span className={styles.field_value}>{userDetails?.email}</span>
      </div>
      <div className={styles.enquiry_field}>
        <span className={styles.field_label}>Status:</span>

        <span className={styles.field_value}>
          {formatStatusName(record.status)}
        </span>
      </div>
      <div className={styles.enquiry_field}>
        <span className={styles.field_label}>Created At:</span>

        <span className={styles.field_value}>
          {new Date(record.created_at).toLocaleString('en-IN', {
            timeZone: 'Asia/Kolkata',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          })}
        </span>
      </div>
    </div>
  );
};

const SortableButton = ({ field, label }) => {
  const { setSort, sort } = useListContext();

  const handleSort = () => {
    const newOrder =
      sort.field === field && sort.order === 'ASC' ? 'DESC' : 'ASC';
    setSort({ field, order: newOrder });
  };

  const isActive = sort.field === field;
  const order = isActive ? sort.order : null;

  return (
    <Button onClick={handleSort} className={styles.sortable_button}>
      <span>{label}</span>
      {isActive && (
        <span
          className={`${styles.sort_indicator} ${styles[order.toLowerCase()]}`}
        >
          {order === 'ASC' ? (
            <ArrowUpwardIcon fontSize="small" />
          ) : (
            <ArrowDownwardIcon fontSize="small" />
          )}
        </span>
      )}
    </Button>
  );
};

const MobileList = () => {
  const { data, isLoading } = useListContext();

  if (isLoading) return null;

  return (
    <div className={styles.mobile_list}>
      <div className={styles.sort_buttons}>
        <SortableButton field="enquiry_number" label="Enquiry" />

        <SortableButton field="user_id" label="User" />

        <SortableButton field="status" label="Status" />

        <SortableButton field="created_at" label="Date" />
      </div>
      {data.map(record => (
        <EnquiryCard key={record.id} record={record} />
      ))}
    </div>
  );
};

export const EnquiryList = props => (
  <List
    {...props}
    filters={<EnquiryFilter />}
    actions={<ListActions />}
    className={styles.enquiry_list}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <div className={styles.desktop_view}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="enquiry_number" label="Enquiry Number" />

        <ReferenceField source="user_id" reference="user_details" label="User">
          <TextField source="email" />
        </ReferenceField>

        <FormatStatusField source="status" />

        <DateField
          source="created_at"
          label="Created At"
          showTime={true}
          options={DATE_FORMAT_OPTIONS}
        />

        <ShowButton label="SHOW" />
      </Datagrid>
    </div>

    <div className={styles.mobile_view}>
      <MobileList />
    </div>
  </List>
);
