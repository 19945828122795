import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const NoPermissionMessage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="calc(100vh - 60px)"
      bgcolor="#f5f5f5"
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 400,
          borderRadius: 2,
        }}
      >
        <ErrorOutlineIcon
          sx={{ fontSize: 64, color: '#f44336', marginBottom: 2 }}
        />

        <Typography variant="h4" gutterBottom fontWeight="bold" color="primary">
          Access Denied
        </Typography>

        <Typography variant="body1" align="center" paragraph>
          {`You don't have permission to view this page. Please contact an
          administrator if you believe this is an error.`}
        </Typography>
      </Paper>
    </Box>
  );
};

export default NoPermissionMessage;
