import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';

const UserSelector = ({ onChange }) => {
  const dataProvider = useDataProvider();
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchUsers = async (searchTerm = '') => {
    setLoading(true);
    try {
      const params = {
        pagination: { page: 1, perPage: 5 },
        sort: { field: 'email', order: 'ASC' },
        filter: { q: searchTerm },
      };
      const { data } = await dataProvider.getList('users', params);
      setUsers(data);
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleChange = (event, newValue) => {
    if (newValue) {
      onChange(newValue.id);

      setSearch('');
    } else {
      onChange('');
    }
  };

  return (
    <Autocomplete
      freeSolo
      loading={loading}
      options={users}
      getOptionLabel={option => option.email || ''}
      filterOptions={x => x}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      inputValue={search}
      onChange={handleChange}
      renderInput={params => (
        <TextField
          {...params}
          label="Search User"
          variant="outlined"
          required
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default UserSelector;
