import React from 'react';
import {
  Edit,
  SimpleForm,
  ImageInput,
  ImageField,
  required,
  useTranslate,
  Toolbar,
  SaveButton,
  useRecordContext,
} from 'react-admin';
import { Grid, Typography, Box } from '@mui/material';
import { ProductInput } from './ProductInput.js';
import { StatusToggleButton } from '../../../components/StatusToggleButton';

import styles from './styles.module.css';

import ConfigurationSection from './ConfigurationSection.js';

const ProductTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate('Product Edit', {
        name: record ? record?.name : '',
      })}
    </span>
  );
};

const EditToolbar = props => {
  const record = useRecordContext();

  return (
    <Toolbar {...props}>
      <SaveButton />

      <Box ml={2}>
        <StatusToggleButton record={record} />
      </Box>
    </Toolbar>
  );
};

export const ProductEdit = props => {
  return (
    <Edit title={<ProductTitle />} {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <Typography variant="h6" className={styles.title}>
          Edit Product
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ProductInput
              source="product_number"
              label="Product Number"
              disabled
            />

            <ProductInput source="name" label="Name" validate={required()} />

            <ProductInput source="price" label="Price" type="number" />
          </Grid>

          <Grid item xs={12} md={6}>
            <ProductInput
              source="description"
              label="Description"
              multiline
              rows={6}
            />

            <ImageInput source="image" accept="image/*" fullWidth>
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>

          <ConfigurationSection source="configurations" />
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
