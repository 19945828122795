import React from 'react';
import { useNotify, useRefresh, useDataProvider } from 'react-admin';
import { Button, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const StatusToggleButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleToggle = async () => {
    try {
      const newStatus = record?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
      await dataProvider.toggleStatus('products', {
        id: record?.id,
        status: newStatus,
      });
      notify(
        `Product ${record?.name} ${
          newStatus === 'ACTIVE' ? 'activated' : 'deactivated'
        } successfully`,
        { type: 'success' }
      );
      refresh();
    } catch (error) {
      notify(`Error: ${error.message}`, { type: 'error' });
    }
  };

  return (
    <Button
      onClick={handleToggle}
      size="medium"
      variant="contained"
      color={record?.status === 'ACTIVE' ? 'error' : 'primary'}
      startIcon={
        record?.status === 'ACTIVE' ? <BlockIcon /> : <CheckCircleIcon />
      }
      sx={{
        borderRadius: '4px',
        padding: '6px 16px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: record?.status === 'ACTIVE' ? '#f28b82' : '#2c6093',
        },
      }}
    >
      <Typography variant="button">
        {record?.status === 'ACTIVE' ? 'Deactivate' : 'Activate'}
      </Typography>
    </Button>
  );
};
