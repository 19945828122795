import React from 'react';
import { useTheme } from '@emotion/react';
import { Box, Card, CardContent, Typography } from '@mui/material';

const StatCard = ({ icon, title, value, color, randomColor }) => {
  const theme = useTheme();
  const cardColor = randomColor || color;

  return (
    <Card
      elevation={3}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s, background-color 0.3s',
        '&:hover': { transform: 'translateY(-5px)' },
        backgroundColor: cardColor,
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '50%',
            padding: 2,
            marginBottom: 2,
            height: '60px',
            width: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {React.cloneElement(icon, {
            fontSize: 'large',
            sx: { color: cardColor },
          })}
        </Box>

        <Typography
          variant="h4"
          component="div"
          gutterBottom
          color={theme.palette.getContrastText(cardColor)}
        >
          {value}
        </Typography>

        <Typography
          variant="subtitle1"
          color={theme.palette.getContrastText(cardColor)}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatCard;
