import React, { useState } from 'react';
import {
  SimpleForm,
  TextInput,
  PasswordInput,
  useCreate,
  useNotify,
  useRedirect,
} from 'react-admin';
import CustomSelectInput from '../../components/common-component/CustomSelectInput';
import CurrencySelector from '../../components/CurrencySelector';
import { Box } from '@mui/material';

export const UserCreate = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const onSubmit = async values => {
    try {
      await create('users', {
        data: { ...values, default_currency: selectedCurrency },
      });
      notify('User created successfully', { type: 'success' });
      redirect('list', 'users');
    } catch (error) {
      notify(`Error: ${error.message}`, { type: 'warning' });
    }
  };

  return (
    <SimpleForm onSubmit={onSubmit}>
      <TextInput source="email" />

      <PasswordInput source="password" />

      <Box display="flex" width="100%" gap={2}>
        <CustomSelectInput
          source="role"
          defaultValue="CUSTOMER"
          choices={[
            { id: 'ADMIN', name: 'Admin' },
            { id: 'SALES_MANAGER', name: 'Sales Manager' },
            { id: 'CUSTOMER', name: 'Customer' },
          ]}
        />

        <CurrencySelector
          selectedCurrency={selectedCurrency}
          onCurrencyChange={setSelectedCurrency}
        />
      </Box>
    </SimpleForm>
  );
};
