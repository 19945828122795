import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ImageInput,
  ImageField,
  required,
  useNotify,
  useRedirect,
  useDataProvider,
} from 'react-admin';
import { PRODUCT_TYPE } from '../../../constants';
import { Box, Button, Grid, Typography, TextField, Chip } from '@mui/material';

import TextAreaInput from '../../../components/common-component/TextAreaInput';
import CustomSelectInput from '../../../components/common-component/CustomSelectInput';

const ConfigurationInput = ({ label, options, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleAddOption = () => {
    if (inputValue.trim()) {
      if (options.includes(inputValue.trim())) {
        setError('This option already exists');
      } else {
        const updatedOptions = [...options, inputValue.trim()];
        setInputValue('');
        setError('');
        onChange(updatedOptions);
      }
    } else {
      setError('Please enter a valid option');
    }
  };

  const handleRemoveOption = optionToRemove => {
    const updatedOptions = options.filter(option => option !== optionToRemove);
    onChange(updatedOptions);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
        {label}
      </Typography>

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} sm={8} md={9}>
          <TextField
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            fullWidth
            placeholder={`Enter ${label.toLowerCase()}`}
            variant="outlined"
            error={!!error}
            helperText={error}
            sx={{
              mt: 0,
              '& .MuiFormHelperText-root': {
                mx: 0,
                mt: 1,
                color: 'error.light',
              },
              '& .MuiOutlinedInput-root': {
                height: '40px',
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddOption}
            fullWidth
            sx={{
              height: '40px',
              textTransform: 'none',
            }}
          >
            Add Option
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {options.map(option => (
          <Chip
            key={option}
            label={option}
            onDelete={() => handleRemoveOption(option)}
            sx={{ m: 0.5 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export const ProductCreate = props => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const [productType, setProductType] = useState('VEHICLE_PART');

  const [configurations, setConfigurations] = useState({
    model: [],
    transmission: [],
    engine_type: [],
    color: [],
  });

  const configurationCategories = [
    { id: 'MODEL', name: 'Model' },
    { id: 'TRANSMISSION', name: 'Transmission' },
    { id: 'ENGINE_TYPE', name: 'Engine Type' },
    { id: 'COLOR', name: 'Color' },
  ];

  const handleConfigurationChange = (categoryId, newOptions) => {
    setConfigurations(prev => ({
      ...prev,
      [categoryId.toLowerCase()]: newOptions,
    }));
  };

  const validateConfigurations = () => {
    return configurationCategories.every(
      category => configurations[category?.id?.toLowerCase()]?.length > 0
    );
  };

  const onSubmit = async values => {
    // Validate configurations only if product type is "VEHICLE"
    if (productType === PRODUCT_TYPE?.VEHICLE && !validateConfigurations()) {
      notify('Each configuration category must have at least one option.', {
        type: 'warning',
      });
      return;
    }

    values.configurations = {};

    configurationCategories.forEach(category => {
      values.configurations[category.id.toLowerCase()] = configurations[
        category.id.toLowerCase()
      ].map(value => ({
        value,
      }));
    });

    try {
      await dataProvider.create('products', { data: values });
      notify('Product created successfully', { type: 'success' });
      redirect('list', 'products');
    } catch (error) {
      notify(`Error: ${error.message}`, { type: 'warning' });
    }
  };

  return (
    <Create {...props}>
      <SimpleForm onSubmit={onSubmit}>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="name"
              validate={required()}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <NumberInput source="price" fullWidth variant="outlined" />
          </Grid>

          <Grid item xs={12}>
            <TextAreaInput
              source="description"
              fullWidth
              rows={4}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
          Product Image
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ImageInput source="image" accept="image/*" label="">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
          Product Type
        </Typography>

        <CustomSelectInput
          source="product_type"
          choices={[
            { id: 'VEHICLE', name: 'Vehicle' },
            { id: 'VEHICLE_PART', name: 'Vehicle Part' },
          ]}
          defaultValue="VEHICLE_PART"
          onChange={e => setProductType(e.target.value)}
        />

        {productType === PRODUCT_TYPE?.VEHICLE && (
          <Box mt={3}>
            <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
              Configuration Options
            </Typography>

            <Grid container spacing={3}>
              {configurationCategories.map(category => (
                <Grid item xs={12} md={6} key={category.id}>
                  <ConfigurationInput
                    label={category.name}
                    options={configurations[category.id.toLowerCase()]}
                    onChange={newOptions =>
                      handleConfigurationChange(category.id, newOptions)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </SimpleForm>
    </Create>
  );
};

export default ProductCreate;
