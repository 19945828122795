import React from 'react';
import {
  Show,
  TextField,
  NumberField,
  ImageField,
  useShowController,
  Loading,
  Error,
} from 'react-admin';
import { formatStatusName } from '../../../common/helpers';
import { CardContent, Grid, Typography, Box, Chip } from '@mui/material';

import styles from './styles.module.css';

const getStatusColor = status => {
  const colors = {
    active: '#4CAF50',
    inactive: '#F44336',
  };
  return colors[status?.toLowerCase()] || '#757575';
};

const ProductTitle = ({ record }) => (
  <span>{record ? `Product Number ${record?.product_number}` : ''}</span>
);

const LabeledField = ({ label, children }) => (
  <div className={styles.labeledField}>
    <Typography variant="caption" className={styles.fieldLabel}>
      {label}
    </Typography>
    {children}
  </div>
);

const ConfigurationSection = ({ configType, values }) => (
  <Box className={styles.configSection}>
    <Typography variant="subtitle2" className={styles.configLabel}>
      {configType.charAt(0).toUpperCase() + configType.slice(1)}
    </Typography>

    <Box className={styles.chipContainer}>
      {values?.map(value => (
        <Chip
          key={value?.id}
          label={value?.value}
          className={styles.chip}
          size="small"
        />
      ))}
    </Box>
  </Box>
);

export const ProductShow = props => {
  const { resource, id } = props;
  const { loading, error, record } = useShowController({ resource, id });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (!record) {
    return null;
  }

  return (
    <Show title={<ProductTitle record={record} />} {...props}>
      <div className={styles.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom className={styles.title}>
            Product Details
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <LabeledField label="Product Number">
                    <TextField source="product_number" />
                  </LabeledField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LabeledField label="Name">
                    <TextField source="name" />
                  </LabeledField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LabeledField label="Product Type">
                    <span>{formatStatusName(record?.product_type)}</span>
                  </LabeledField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LabeledField label="Status">
                    <Chip
                      label={formatStatusName(record?.status)}
                      size="small"
                      style={{
                        backgroundColor: getStatusColor(record?.status),
                        color: '#FFFFFF',
                        fontWeight: 500,
                        fontSize: '0.875rem',
                      }}
                    />
                  </LabeledField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LabeledField label="Price">
                    <NumberField
                      source="price"
                      emptyText="N/A"
                      options={{ style: 'currency', currency: 'INR' }}
                    />
                  </LabeledField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LabeledField label="Description">
                    <TextField source="description" />
                  </LabeledField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <LabeledField label="Product Image">
                <ImageField
                  source="image_url"
                  title="image_alt"
                  className={styles.imageField}
                />
              </LabeledField>
            </Grid>
          </Grid>

          {record?.product_configurations &&
            Object.keys(record?.product_configurations).length > 0 && (
              <Box mt={3}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={styles.subtitle}
                >
                  Configurations
                </Typography>

                <Grid container spacing={2}>
                  {Object.entries(record?.product_configurations).map(
                    ([configType, values]) => (
                      <Grid item xs={12} sm={6} md={4} key={configType}>
                        <ConfigurationSection
                          configType={formatStatusName(configType)}
                          values={values}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            )}
        </CardContent>
      </div>
    </Show>
  );
};

export default ProductShow;
