import React, { useState } from 'react';
import { Modal, Button, Typography, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles.module.css';
import { formatStatusName } from '../../../common/helpers';

const ConfigurationModal = ({ open, onClose, product, onSave }) => {
  const [selectedConfigurations, setSelectedConfigurations] = useState({});

  const handleSelectOption = (optionName, value) => {
    setSelectedConfigurations(prev => ({ ...prev, [optionName]: value }));
  };

  const isAllSelected =
    Object.keys(product.product_configurations).length ===
    Object.keys(selectedConfigurations).length;

  const handleSave = () => {
    if (isAllSelected) {
      onSave(product, selectedConfigurations);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={styles.modalContent}>
        <IconButton className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" className={styles.modalTitle}>
          Customise Your Vehicle
        </Typography>

        <Box className={styles.productInfo}>
          <img
            src={product.image_url}
            alt={product.name}
            className={styles.productImage}
          />
          <Box>
            <Typography variant="h6">{product.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {product.description}
            </Typography>
          </Box>
        </Box>

        {Object.entries(product.product_configurations).map(
          ([optionName, options]) => (
            <Box key={optionName} className={styles.configurationSection}>
              <Typography
                variant="subtitle1"
                className={styles.configurationTitle}
              >
                Choose {formatStatusName(optionName)}
              </Typography>
              <Box className={styles.optionButtonGroup}>
                {options.map(option => (
                  <Button
                    key={option.id}
                    variant={
                      selectedConfigurations[optionName] === option.id
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => handleSelectOption(optionName, option.id)}
                    className={styles.optionButton}
                  >
                    {option.value}
                  </Button>
                ))}
              </Box>
            </Box>
          )
        )}

        {!isAllSelected && (
          <Typography
            variant="body2"
            color="error"
            className={styles.errorText}
          >
            At least one selection is required
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={!isAllSelected}
          onClick={handleSave}
          className={styles.addToCartButton}
        >
          Add to Cart
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfigurationModal;
