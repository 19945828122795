import loginContent from '../content/loginContent.json';

const loginContentParser = {
  formHeading: loginContent?.formHeading || 'N/A',
  formFields: loginContent?.formFields || [],
  buttonLoadingText: loginContent?.buttonLoadingText || 'N/a',
  submitButtonText: loginContent?.submitButtonText || 'N/A',
  loginSuccessMessage: loginContent?.loginSuccessMessage || 'N/A',
  loginFailedMessage: loginContent?.loginFailedMessage || 'N/A',
  forgotPasswordText: loginContent?.forgotPasswordText || 'N/A',
  forgotPasswordSubtext: loginContent?.forgotPasswordSubtext || 'N/A',
  welcomeTitle: loginContent?.welcomeTitle || 'N/A',
  welcomeSubtitle: loginContent?.welcomeSubtitle || 'N/A',
  welcomeSubtext: loginContent?.welcomeSubtext || 'N/A',
};

export default loginContentParser;
