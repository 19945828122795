import { useGetList } from 'react-admin';

export const useFetchResourceData = (
  resource,
  filter = {},
  sort = { field: 'id', order: 'DESC' },
  pagination = { page: 1, perPage: 10 }
) => {
  const { data, total, isLoading, error } = useGetList(
    resource,
    pagination,
    sort,
    filter
  );

  return { data, total, isLoading, error };
};
