import React, { memo } from 'react';
import { Filter, TextInput } from 'react-admin';
import CustomSelectInput from '../../../components/common-component/CustomSelectInput';
import { PRODUCT_STATUS_CHOICES, PRODUCT_OPTION } from '../../../constants';

import styles from './styles.module.css';

const ProductFilter = memo(props => (
  <Filter {...props}>
    <TextInput
      source="q"
      label="Search for Vehicles or Parts"
      alwaysOn
      variant="outlined"
      fullWidth
      className={styles?.search_input}
    />

    <CustomSelectInput
      source="status"
      label="Filter By Status"
      choices={PRODUCT_STATUS_CHOICES}
      alwaysOn
    />

    <CustomSelectInput
      source="product_type"
      label="Filter By Type"
      choices={PRODUCT_OPTION}
      alwaysOn
    />
  </Filter>
));

export default ProductFilter;
