import React from 'react';
import { ENQUIRY_STATUS } from '../../../constants';
import { Filter, TextInput, SelectInput } from 'react-admin';

const statusChoices = [
  { id: ENQUIRY_STATUS?.SUBMITTED, name: 'Enquiry Submit' },
  { id: ENQUIRY_STATUS?.QUOTATION_GENERATED, name: 'Quotation Generated' },
  { id: ENQUIRY_STATUS?.CLOSED, name: 'Enquiry Closed' },
];

const EnquiryFilter = props => (
  <Filter {...props}>
    <TextInput source="q" label="Search" alwaysOn variant="outlined" />

    <SelectInput
      source="status"
      label="Status"
      choices={statusChoices}
      optionText="name"
      optionValue="id"
      alwaysOn
      variant="outlined"
      emptyText="All"
      emptyValue=""
    />
  </Filter>
);

export default EnquiryFilter;
