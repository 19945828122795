import React, { useState } from 'react';
import {
  Show,
  SimpleShowLayout,
  DateField,
  useShowController,
  Link,
  useDataProvider,
  useRefresh,
} from 'react-admin';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';

import { formatCurrency } from '../../../common/helpers';
import { DATE_FORMAT_OPTIONS, PRODUCT_TYPE } from '../../../constants';

import ShippingDetailsUpload from '../../../components/ShippingDetailsUpload';

import styles from './styles.module.css';

const formatStatusName = status => {
  if (!status) return 'N/A';
  return status
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\b\w/g, l => l.toUpperCase());
};

const StatusChip = ({ status, type }) => {
  const getStatusColor = (status, type) => {
    if (type === 'invoice') {
      switch (status) {
        case 'PENDING_PAYMENT':
          return '#FFA000';
        case 'PAID':
          return '#4CAF50';
        default:
          return '#9E9E9E';
      }
    } else if (type === 'quotation') {
      switch (status) {
        case 'CUSTOMER_ACCEPTED':
          return '#4CAF50';
        case 'CUSTOMER_PENDING_REVIEW':
          return '#FFA000';
        case 'CUSTOMER_REJECTED':
          return '#F44336';
        default:
          return '#9E9E9E';
      }
    }
  };

  return (
    <Chip
      label={formatStatusName(status)}
      className={styles.chip}
      style={{
        backgroundColor: getStatusColor(status, type),
      }}
    />
  );
};

const DetailItem = ({ label, value }) => (
  <Box className={styles.detailItem}>
    <Typography
      variant="subtitle2"
      className={styles.detailItemLabel}
      gutterBottom
    >
      {label}
    </Typography>
    <Typography variant="body1">{value}</Typography>
  </Box>
);

const renderProductConfigurations = configurations => {
  if (!configurations || configurations.length === 0) return null;

  return (
    <Box mt={1} mb={2}>
      <Typography variant="subtitle2" gutterBottom>
        Vehicle Configuration:
      </Typography>

      <Typography variant="body2" className={styles.configOptions}>
        {configurations?.map((config, index) => (
          <React.Fragment key={index}>
            <span className={styles.configOption}>
              <span className={styles.configLabel}>
                {formatStatusName(config.category)}:
              </span>{' '}
              {config.value}
            </span>
            {index < configurations.length - 1 && (
              <span className={styles.separator}>|</span>
            )}
          </React.Fragment>
        ))}
      </Typography>
    </Box>
  );
};

export const InvoiceShow = props => {
  const { record } = useShowController(props);

  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);

  if (!record) return null;

  const { quotation, pdfUrl } = record;

  const handleOpenConfirmation = paymentId => {
    setSelectedPaymentId(paymentId);
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setSelectedPaymentId(null);
  };

  const handleConfirmSubmit = async () => {
    setLoading(true);
    try {
      await dataProvider.confirmAdvancedPayment(selectedPaymentId);
      refresh();
      setOpenConfirmation(false);
    } catch (error) {
      throw new Error('Error verifying payment');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Show {...props} className={styles.showContainer}>
      <SimpleShowLayout>
        <Card className={styles.cardContainer}>
          <Typography variant="h6" className={styles.cardTitle}>
            Invoice Details
          </Typography>

          <CardContent className={styles.cardContent}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <DetailItem
                  label="Invoice Number"
                  value={record.invoiceNumber || 'N/A'}
                />

                <DetailItem
                  label="Quotation Number"
                  value={quotation?.quotationNumber || 'N/A'}
                />

                <DetailItem
                  label="Enquiry Number"
                  value={record.enquiryNumber || 'N/A'}
                />

                <DetailItem
                  label="User Email"
                  value={record.userEmail || 'N/A'}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <DetailItem
                  label="Invoice Amount"
                  value={formatCurrency(
                    record.invoiceAmount,
                    record?.currency_data?.currencyCode
                  )}
                />

                <DetailItem
                  label="Quotation Amount"
                  value={formatCurrency(
                    quotation?.quotationAmount,
                    record?.currency_data?.currencyCode
                  )}
                />

                <DetailItem
                  label="Invoice Created At"
                  value={
                    <DateField
                      source="createdAt"
                      showTime
                      options={DATE_FORMAT_OPTIONS}
                    />
                  }
                />

                <DetailItem
                  label="Invoice Last Updated"
                  value={
                    <DateField
                      source="updatedAt"
                      showTime
                      options={DATE_FORMAT_OPTIONS}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <DetailItem
                  label="Invoice Status"
                  value={<StatusChip status={record.status} type="invoice" />}
                />

                <DetailItem
                  label="Quotation Status"
                  value={
                    <StatusChip status={quotation?.status} type="quotation" />
                  }
                />

                <DetailItem
                  label="Enquiry Status"
                  value={
                    <StatusChip status={record.enquiryStatus} type="invoice" />
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Products Table */}
        <Card className={styles.cardContainer}>
          <Typography variant="h6" className={styles.cardTitle}>
            Products
          </Typography>

          <CardContent className={styles.cardContent}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHeadCell}>
                    Product
                  </TableCell>

                  <TableCell align="right" className={styles.tableHeadCell}>
                    Product Number
                  </TableCell>

                  <TableCell align="right" className={styles.tableHeadCell}>
                    Product Description
                  </TableCell>

                  <TableCell align="right" className={styles.tableHeadCell}>
                    Product Type
                  </TableCell>

                  <TableCell align="right" className={styles.tableHeadCell}>
                    Quantity
                  </TableCell>

                  <TableCell align="right" className={styles.tableHeadCell}>
                    Price
                  </TableCell>

                  <TableCell align="right" className={styles.tableHeadCell}>
                    Subtotal
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {quotation?.products?.map(product => (
                  <React.Fragment key={product.id}>
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom:
                            product?.product?.productType ===
                            PRODUCT_TYPE?.VEHICLE
                              ? 'none'
                              : undefined,
                        }}
                      >
                        {product?.product?.name ? (
                          <Link to={`/products/${product?.product?.id}/show`}>
                            {product?.product?.name}
                          </Link>
                        ) : (
                          'Custom Product'
                        )}
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{
                          borderBottom:
                            product?.product?.productType ===
                            PRODUCT_TYPE?.VEHICLE
                              ? 'none'
                              : undefined,
                        }}
                      >
                        {product?.product?.productNumber || 'N/A'}
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{
                          borderBottom:
                            product?.product?.productType ===
                            PRODUCT_TYPE?.VEHICLE
                              ? 'none'
                              : undefined,
                        }}
                      >
                        {product?.product?.description || 'N/A'}
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{
                          borderBottom:
                            product?.product?.productType ===
                            PRODUCT_TYPE?.VEHICLE
                              ? 'none'
                              : undefined,
                        }}
                      >
                        {formatStatusName(product?.product?.productType)}
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{
                          borderBottom:
                            product?.product?.productType ===
                            PRODUCT_TYPE?.VEHICLE
                              ? 'none'
                              : undefined,
                        }}
                      >
                        {product.quantity}
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{
                          borderBottom:
                            product?.product?.productType ===
                            PRODUCT_TYPE?.VEHICLE
                              ? 'none'
                              : undefined,
                        }}
                      >
                        {formatCurrency(
                          product.price,
                          record?.currency_data?.currencyCode
                        )}
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{
                          borderBottom:
                            product?.product?.productType ===
                            PRODUCT_TYPE?.VEHICLE
                              ? 'none'
                              : undefined,
                        }}
                      >
                        {formatCurrency(
                          product.price * product.quantity,
                          record?.currency_data?.currencyCode
                        )}
                      </TableCell>
                    </TableRow>
                    {product?.product?.productType ===
                      PRODUCT_TYPE?.VEHICLE && (
                      <TableRow>
                        <TableCell
                          colSpan={7}
                          style={{ paddingTop: 0, paddingBottom: 16 }}
                        >
                          {renderProductConfigurations(
                            product.quotationConfigurations
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}

                <TableRow>
                  <TableCell>
                    {pdfUrl && (
                      <Button
                        variant="contained"
                        color="primary"
                        href={pdfUrl}
                        target="_blank"
                        style={{ marginTop: '10px' }}
                      >
                        View Invoice PDF
                      </Button>
                    )}
                  </TableCell>
                  <TableCell colSpan={5} align="right">
                    <strong>Total:</strong>
                  </TableCell>

                  <TableCell align="right">
                    <strong>
                      {formatCurrency(
                        record.invoiceAmount,
                        record?.currency_data?.currencyCode
                      )}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        {/* Payment List Section */}
        {record?.payments?.length && (
          <Card className={styles.cardContainer}>
            <Typography variant="h6" className={styles.cardTitle}>
              Payments
            </Typography>

            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Document Link</TableCell>

                    <TableCell>Upload Date</TableCell>

                    <TableCell>Payment Confirmation Date</TableCell>

                    <TableCell>Status</TableCell>

                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {record?.payments?.map(payment => (
                    <TableRow key={payment.id}>
                      <TableCell>
                        <a
                          href={payment?.paymentFile}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {payment?.paymentFile
                            ? payment?.paymentFile.split('/').pop()
                            : 'No File'}
                        </a>
                      </TableCell>

                      <TableCell>
                        <DateField
                          source="paymentDate"
                          record={payment}
                          showTime
                          options={DATE_FORMAT_OPTIONS}
                        />
                      </TableCell>

                      <TableCell>
                        <DateField
                          source="paymentConfirmAt"
                          record={payment}
                          showTime
                          options={DATE_FORMAT_OPTIONS}
                        />
                      </TableCell>

                      <TableCell>
                        {payment?.verifiedPayment ? (
                          <Chip label="Confirmed" color="success" />
                        ) : (
                          <Chip label="Pending" color="warning" />
                        )}
                      </TableCell>

                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={payment?.verifiedPayment}
                          onClick={() => handleOpenConfirmation(payment.id)}
                        >
                          Confirm Payment
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}

        {/* Shipping Details */}
        {record?.status !== 'PENDING_PAYMENT' && (
          <ShippingDetailsUpload
            userId={record?.userId}
            enquiryId={record?.enquiryId}
            enquiryStatus={record?.enquiryStatus}
            enquiryNumber={record?.enquiryNumber}
            currentTrackingUrl={record?.trackingUrl}
            currentTrackingInfo={record?.trackingInformation}
            shippingImages={record?.shippingImages}
          />
        )}

        {/* Confirmation Dialog */}
        <Dialog
          open={openConfirmation}
          onClose={handleCloseConfirmation}
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        >
          <DialogTitle>Verify Payment</DialogTitle>

          <DialogContent>
            <Typography>
              Are you sure you want to confirm this payment?
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseConfirmation} disabled={loading}>
              Cancel
            </Button>

            <Button
              onClick={handleConfirmSubmit}
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Confirm'}
            </Button>
          </DialogActions>
        </Dialog>
      </SimpleShowLayout>
    </Show>
  );
};

export default InvoiceShow;
