import React from 'react';
import { Grid, CardContent, Typography } from '@mui/material';
import ListWithPermissions from '../common-component/ListWithPermissions';

const ManagementSection = ({ title, resource, children }) => {
  return (
    <Grid item xs={12} style={{ marginTop: 16 }}>
      <div
        style={{
          borderRadius: '4px',
          boxShadow:
            '0px 2px 1px -1px rgba(1, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>

          <ListWithPermissions resource={resource} perPage={5}>
            {children}
          </ListWithPermissions>
        </CardContent>
      </div>
    </Grid>
  );
};

export default ManagementSection;
