import React, { useEffect, useState, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import {
  Autocomplete,
  TextField,
  Chip,
  Avatar,
  Box,
  Typography,
} from '@mui/material';

const CurrencySelector = ({
  userId = null,
  selectedCurrency,
  onCurrencyChange,
}) => {
  const dataProvider = useDataProvider();
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCurrencies = useCallback(async () => {
    try {
      const { data } = await dataProvider.getList('currencies', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
      });

      setCurrencies(data);

      if (!selectedCurrency) {
        let defaultCurrency;

        if (userId) {
          try {
            const userCurrency = await dataProvider.getOne('user_details', {
              id: userId,
            });

            defaultCurrency = data?.find(
              currency => currency?.id === userCurrency?.data?.default_currency
            );
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching user details:', error);
          }
        }

        // If no user-specific currency is found, fall back to INR or the first currency
        defaultCurrency =
          defaultCurrency ||
          data?.find(currency => currency?.code === 'INR') ||
          data?.[0];

        if (defaultCurrency) {
          onCurrencyChange(defaultCurrency);
        }
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [dataProvider, onCurrencyChange, selectedCurrency, userId]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  if (loading) {
    return <></>;
  }

  if (error) {
    return <div>Error loading currencies: {error.message}</div>;
  }

  return (
    <Autocomplete
      sx={{ mb: 3, maxWidth: 340 }}
      value={selectedCurrency}
      onChange={(event, newValue) => {
        if (newValue) {
          onCurrencyChange(newValue);
        }
      }}
      options={currencies}
      getOptionLabel={option => `${option.name} (${option.code})`}
      renderInput={params => (
        <TextField {...params} label="Currency" variant="outlined" />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
            <Avatar
              src={`/flags/${option.code.toLowerCase()}.png`}
              alt={option.code}
              sx={{ width: 20, height: 20, mr: 1 }}
            />
            <Typography>
              {option.name} ({option.code})
            </Typography>
          </Box>
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={`${option.name} (${option.code})`}
            {...getTagProps({ index })}
            avatar={
              <Avatar
                src={`/flags/${option.code.toLowerCase()}.png`}
                alt={option.code}
              />
            }
            sx={{ height: 32, mr: 1 }}
          />
        ))
      }
    />
  );
};

export default CurrencySelector;
