import React from 'react';
import { List, Datagrid, usePermissions } from 'react-admin';
import NoPermissionMessage from '../NoPermissionMessage/index.js';

const ListWithPermissions = ({ resource, children, ...props }) => {
  const { permissions } = usePermissions();

  if (permissions !== 'ADMIN' && permissions !== 'SALES_MANAGER') {
    return <NoPermissionMessage />;
  }

  return (
    <List
      resource={resource}
      {...props}
      actions={null}
      bulkActionButtons={false}
      title={false}
    >
      <Datagrid bulkActionButtons={false}>{children}</Datagrid>
    </List>
  );
};

export default ListWithPermissions;
