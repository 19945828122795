import React, { memo } from 'react';
import { TopToolbar, CreateButton, ExportButton } from 'react-admin';

const ListActions = memo(() => (
  <TopToolbar>
    <CreateButton />

    <ExportButton />
  </TopToolbar>
));

export default ListActions;
