import currencyLocales from '../content/currencyLocales.json';

export const getFormFieldText = (formFields, fieldId, textType) => {
  const field = formFields?.find(field => field?.id === fieldId);
  return field ? field[textType] : '';
};

export const formatStatusName = str => {
  return str
    ?.toLowerCase()
    ?.split('_')
    ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(' ');
};

export const formatCurrency = (amount, currencyCode = 'INR') => {
  const locale = currencyLocales[currencyCode] || 'en-IN';

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  })?.format(amount);
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
