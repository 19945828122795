import React from 'react';
import { TextInput } from 'react-admin';

const TextAreaInput = props => {
  return (
    <TextInput
      {...props}
      multiline
      fullWidth
      label="Short Description"
      required
      rows={3}
    />
  );
};

export default TextAreaInput;
