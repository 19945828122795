import React from 'react';
import { Filter, TextInput, SelectInput } from 'react-admin';

const statusChoices = [
  { id: 'PENDING_PAYMENT', name: 'Pending Payment' },
  { id: 'PAID', name: 'Paid' },
  { id: 'CANCELLED', name: 'Cancelled' },
];

const InvoiceFilter = props => (
  <Filter {...props}>
    <TextInput
      source="q"
      label="Search by Invoice Number"
      variant="outlined"
      alwaysOn
    />
    <SelectInput
      source="status"
      label="Filter by Status"
      choices={statusChoices}
      optionText="name"
      optionValue="id"
      alwaysOn
      variant="outlined"
      emptyText="All"
      emptyValue=""
    />
  </Filter>
);

export default InvoiceFilter;
